import { isDesktop } from "react-device-detect";
import "../../../css/GlobalStyles.css";
import {
  PhoneOnboardingStage,
  VerificationAccountType,
  VerificationInfo,
} from "../../../screens/Subscription/SubscriptionPhoneOnboarding";
import { Colors } from "../../../utils/colors";
import RectangleButton from "../../Buttons/RectangleButton";
import { useCallback, useMemo, useState } from "react";
import CustomTextField from "../../CustomTextField";
import CustomPhoneTextField from "../../TextFields/CustomPhoneTextField";
import { showPhoneInputError } from "../../../utils/phoneUtils";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";
import { useTheme } from "../../../hooks/useTheme";

type PhoneOnboardingEnterInfoProps = {
  selectedAccountType: VerificationAccountType;
  onboardingInfo: VerificationInfo;
  setOnboardingInfo: (info: VerificationInfo) => void;
  setOnboardingStage: (stage: PhoneOnboardingStage) => void;
};

const PhoneOnboardingEnterInfo = (props: PhoneOnboardingEnterInfoProps) => {
  const {
    selectedAccountType,
    onboardingInfo,
    setOnboardingInfo,
    setOnboardingStage,
  } = props;
  const { theme } = useTheme();
  const [showError, setShowError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState({
    isError: false,
    message: "",
  });
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const isBusiness = useMemo(
    () => selectedAccountType === VerificationAccountType.BUSINESS,
    [selectedAccountType]
  );

  const invalidWebsiteUrl = useMemo(
    () =>
      onboardingInfo.businessWebsite !== "" &&
      !onboardingInfo.businessWebsite.startsWith("https://"),
    [onboardingInfo.businessWebsite]
  );

  const invalidStateCode = useMemo(
    () =>
      onboardingInfo.businessStateProvinceRegion !== "" &&
      onboardingInfo.businessStateProvinceRegion.length > 2,
    [onboardingInfo.businessStateProvinceRegion]
  );

  const verifyInfoOnPress = useCallback(() => {
    if (Object.values(onboardingInfo).some((value) => value === "")) {
      setShowError(true);
      return;
    }
    if (
      showPhoneInputError(
        onboardingInfo.businessContactPhone,
        setPhoneNumberError
      )
    ) {
      return;
    }
    if (invalidWebsiteUrl || invalidStateCode) {
      setShowError(true);
      return;
    }
    setOnboardingStage(PhoneOnboardingStage.MESSAGE_VOLUME);
  }, [invalidStateCode, invalidWebsiteUrl, onboardingInfo, setOnboardingStage]);

  const setOnboardingField = useCallback(
    (field: keyof VerificationInfo, value: string) => {
      setOnboardingInfo({ ...onboardingInfo, [field]: value });
      setShowError(false);
    },
    [onboardingInfo, setOnboardingInfo]
  );

  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const streetAddress = results[0].formatted_address.split(",")[0];
    const addressComponents = results[0].address_components;

    const localAddress = {
      city: "",
      state: "",
      zip: "",
    };
    const city = addressComponents.find((c) =>
      c.types.includes("locality")
    )?.long_name;
    if (city) {
      localAddress.city = city;
    }
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    )?.short_name;
    if (state) {
      localAddress.state = state;
    }
    const zip = addressComponents.find((c) =>
      c.types.includes("postal_code")
    )?.long_name;
    if (zip) {
      localAddress.zip = zip;
    }

    setOnboardingInfo({
      ...onboardingInfo,
      businessStreetAddress: streetAddress,
      businessCity: localAddress.city,
      businessStateProvinceRegion: localAddress.state,
      businessPostalCode: localAddress.zip,
    });
    setIsSearchModalOpen(false);
  };

  const renderInputField = useCallback(
    (
      label: string,
      placeholder: string,
      value: string,
      setValue: (value: string) => void,
      footerText?: string,
      isError?: boolean
    ) => {
      return (
        <div className="ColumnNormal" style={{ gap: 7, width: "100%" }}>
          <span className="bodySmallMedium">{label}</span>
          <CustomTextField
            value={value}
            placeholder={placeholder}
            inputMode="text"
            borderRadius={12}
            onChange={(name: any) => {
              setValue(name.target.value);
            }}
            maxLength={60}
            error={value === "" && showError}
            width="100%"
          />
          {footerText ? (
            <span
              style={{
                fontSize: 12,
                color: isError ? Colors.RED1 : Colors.GRAY2,
              }}
            >
              {footerText}
            </span>
          ) : null}
        </div>
      );
    },
    [showError]
  );

  return (
    <>
      <div
        className={isDesktop ? "AlignedColumn" : "ColumnNormal"}
        style={{ gap: 14, width: isDesktop ? 330 : undefined }}
      >
        {renderInputField(
          "Account Name",
          "Account Name",
          onboardingInfo.businessName,
          (value: string) => setOnboardingField("businessName", value),
          "*You will not be able to change this after submission"
        )}
        {renderInputField(
          isBusiness ? "Account Holder First Name" : "First Name",
          "First Name",
          onboardingInfo.businessContactFirstName,
          (value: string) =>
            setOnboardingField("businessContactFirstName", value)
        )}
        {renderInputField(
          isBusiness ? "Account Holder Last Name" : "Last Name",
          "Last Name",
          onboardingInfo.businessContactLastName,
          (value: string) =>
            setOnboardingField("businessContactLastName", value)
        )}
        {renderInputField(
          isBusiness ? "Business/Organization Email" : "Email",
          "Email",
          onboardingInfo.businessContactEmail,
          (value: string) => setOnboardingField("businessContactEmail", value)
        )}
        <div className="ColumnNormal" style={{ gap: 7, width: "100%" }}>
          <span className="bodySmallMedium">
            {isBusiness ? "Business/Organization Phone Number" : "Phone number"}
          </span>
          <CustomPhoneTextField
            placeholder="Phone number"
            phoneNumber={onboardingInfo.businessContactPhone}
            onPhoneChange={(value) => {
              setOnboardingField("businessContactPhone", value);
            }}
            showPhoneError={phoneNumberError}
          />
        </div>
        {renderInputField(
          isBusiness ? "Business/Organization Website" : "Website",
          "Website URL",
          onboardingInfo.businessWebsite,
          (value: string) => setOnboardingField("businessWebsite", value),
          invalidWebsiteUrl
            ? "Your website URL must start with https://"
            : undefined,
          invalidWebsiteUrl
        )}
        <div className="ColumnNormal" style={{ gap: 7, width: "100%" }}>
          <span className="bodySmallMedium">
            {isBusiness ? "Business/Organization Address" : "Address"}
          </span>
          <PlacesAutocomplete
            value={onboardingInfo.businessStreetAddress}
            onChange={(value) => {
              setOnboardingField("businessStreetAddress", value);
            }}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ width: "100%", position: "relative" }}>
                <input
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    padding: "10px 14px",
                    fontSize: 14,
                    borderRadius: 12,
                    border: "1px solid",
                    borderColor:
                      onboardingInfo.businessStreetAddress === "" && showError
                        ? theme.ErrorBorderColor.borderColor
                        : isSearchModalOpen
                        ? theme.FocusedTextFieldBorder.borderColor
                        : theme.EditableTextFieldBorder.borderColor,
                  }}
                  {...getInputProps({
                    placeholder: "Address",
                    className: "location-search-input",
                  })}
                  onClick={() => setIsSearchModalOpen(true)}
                  onFocus={() => setIsSearchModalOpen(true)}
                  onBlur={() => setIsSearchModalOpen(false)}
                />
                {isSearchModalOpen &&
                onboardingInfo.businessStreetAddress !== "" ? (
                  <AnimatePresence>
                    <LazyMotion features={domAnimation}>
                      <m.div
                        className="HideScrollbar autocomplete-dropdown-container"
                        initial={{ y: -10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                          zIndex: 1000,
                          left: 0,
                          right: 0,
                          position: "absolute",
                          marginTop: 4,
                          border: `1px solid ${theme.DividerColor.borderColor}`,
                          borderRadius: 14,
                          padding: 4,
                          ...theme.SecondaryBG,
                        }}
                      >
                        {loading ? (
                          <div style={{ padding: 10 }}>
                            <span className="bodySubtext">Loading...</span>
                          </div>
                        ) : suggestions.length > 0 ? (
                          suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                                className="ColumnNormalSelect LocationItemLight"
                                style={{ gap: 4, padding: 10 }}
                                key={suggestion.id}
                              >
                                <span className="bodyMedium">
                                  {suggestion.formattedSuggestion.mainText}
                                </span>
                                <span className="smallBodySubtext">
                                  {suggestion.formattedSuggestion.secondaryText}
                                </span>
                              </div>
                            );
                          })
                        ) : (
                          <div style={{ padding: 10 }}>
                            <span className="bodySubtext">
                              No Results Found
                            </span>
                          </div>
                        )}
                      </m.div>
                    </LazyMotion>
                  </AnimatePresence>
                ) : null}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        {renderInputField(
          "City",
          "City",
          onboardingInfo.businessCity,
          (value: string) => setOnboardingField("businessCity", value)
        )}
        <div
          className="AlignedRow"
          style={{ gap: 14, alignItems: "flex-start" }}
        >
          {renderInputField(
            "State",
            "State",
            onboardingInfo.businessStateProvinceRegion,
            (value: string) =>
              setOnboardingField("businessStateProvinceRegion", value),
            invalidStateCode
              ? "Enter based on the two-letter postal code"
              : undefined,
            invalidStateCode
          )}
          {renderInputField(
            "Postal Code",
            "12345",
            onboardingInfo.businessPostalCode,
            (value: string) => setOnboardingField("businessPostalCode", value)
          )}
        </div>
      </div>
      <div
        className="ColumnNormal"
        style={{ width: isDesktop ? 330 : undefined, gap: 24 }}
      >
        <RectangleButton
          buttonLabel="Continue"
          containerStyles={{ paddingInline: 10, paddingBlock: 14 }}
          altTextColor={Colors.WHITE}
          altColor={Colors.BLACK}
          onPress={verifyInfoOnPress}
        />
      </div>
    </>
  );
};

export default PhoneOnboardingEnterInfo;
