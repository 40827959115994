import React, { CSSProperties, memo } from "react";
import {
  AccountData,
  TwilioPhoneVerificationStatus,
} from "@markit/common.types";
import {
  hasSubscription,
  userPhoneVerificationStatus,
} from "@markit/common.utils";
import FullEventHosts from "../FullEvent/FullEventHosts";
import Banner, { BannerType } from "../Banner";
import { useTheme } from "../../hooks/useTheme";

type OrganizersInfoDisplayProps = {
  host: AccountData;
  organizers: AccountData[];
  isProfilePage?: boolean;
  containerStyles?: CSSProperties;
};

// Displays the host and organizers with their socials as well as the contact host banner if the host has a subscription
// Used in FullEventDesktop, ProfilePage
const OrganizersInfoDisplay = memo(function OrganizersInfoDisplayFn(
  props: OrganizersInfoDisplayProps
) {
  const { host, organizers, isProfilePage, containerStyles } = props;
  const { theme } = useTheme();

  return (
    <div
      style={{
        ...theme.SecondaryBG,
        padding: 14,
        borderRadius: 8,
        ...containerStyles,
      }}
    >
      <FullEventHosts
        host={host}
        hostAndCohosts={organizers}
        isProfilePage={isProfilePage}
      />
      {host &&
      hasSubscription(host) &&
      userPhoneVerificationStatus(host) ===
        TwilioPhoneVerificationStatus.APPROVED ? (
        <div style={{ marginTop: "14px" }}>
          <Banner type={BannerType.TEXTHOST} hostPhone={host.customer.phone} />
        </div>
      ) : null}
    </div>
  );
});

export default OrganizersInfoDisplay;
