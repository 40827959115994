import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { EventDashboardExternalLinkCopy } from "../Links/CopyCustomLinkify";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { TwilioPhoneVerificationStatus } from "@markit/common.types";
import { useMemo } from "react";
import VerificationAccountStatusItem from "../CreatorDashboard/VerificationAccountStatusItem";
import { userPhoneVerificationStatus } from "@markit/common.utils";

const ShareMarkitNumber = () => {
  const { accountData } = useSelector(getAccountState).account;

  const styles = {
    container: {
      paddingBlock: 14,
      borderRadius: 20,
      backgroundColor: Colors.WHITE,
    },
  };

  const notApprovedAccountStatus = useMemo(
    () =>
      userPhoneVerificationStatus(accountData) !==
      TwilioPhoneVerificationStatus.APPROVED,
    [accountData]
  );

  return (
    <StandardBorderedContainer containerStyles={styles.container}>
      <div className="ColumnNormal" style={{ gap: 4, paddingInline: 14 }}>
        <div className="AlignedRow" style={{ gap: 10 }}>
          <span className="sectionTitle">Share Number</span>
          <Icon icon="ion:call" height={20} />
        </div>
        <span className="smallBodySubtext">
          Share your unique Markit number. When people text your number they can
          join your audience.
        </span>
      </div>
      <HorizontalDivider altMargin={14} />
      <div className="ColumnNormal" style={{ paddingInline: 14 }}>
        {notApprovedAccountStatus ? (
          <VerificationAccountStatusItem expanded />
        ) : (
          <EventDashboardExternalLinkCopy
            copyText={formatPhoneNumber(accountData.customer.phone)}
            containerStyles={{
              display: "flex",
              padding: 14,
              backgroundColor: Colors.GRAY6,
              borderColor: Colors.GRAY1,
              borderWidth: 0.5,
            }}
            altTextColor={Colors.BLACK}
          />
        )}
      </div>
    </StandardBorderedContainer>
  );
};

export default ShareMarkitNumber;
