import { isDesktop } from "react-device-detect";
import { useCallback, useMemo, useState } from "react";
import "../../../css/GlobalStyles.css";
import {
  messageVolumeOptions,
  VerificationInfo,
} from "../../../screens/Subscription/SubscriptionPhoneOnboarding";
import { Colors } from "../../../utils/colors";
import RectangleButton from "../../Buttons/RectangleButton";
import { DropdownMenu, DropdownMenuItem } from "../../DropdownMenu";
import { Icon } from "@iconify/react";
import { API } from "../../../API";
import { useSelector, useDispatch } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";
import { NavigationId } from "../../../navigation/AppParamList";
import { OnboardingStage } from "../../../screens/Login/LoginOnboarding";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import { NotificationType } from "@markit/common.types";

type PhoneOnboardingMessageVolumeProps = {
  onboardingInfo: VerificationInfo;
  setOnboardingInfo: (info: VerificationInfo) => void;
};

const PhoneOnboardingMessageVolume = (
  props: PhoneOnboardingMessageVolumeProps
) => {
  const { onboardingInfo, setOnboardingInfo } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid } = useSelector(getAccountState).account.accountData;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () =>
      messageVolumeOptions.map((volume) => ({
        title: `${volume.toLocaleString()} texts/mo`,
        icon:
          volume === onboardingInfo.messageVolume
            ? "ion:checkmark-circle"
            : undefined,
        key: volume,
        onPress: () =>
          setOnboardingInfo({ ...onboardingInfo, messageVolume: volume }),
      })),
    [onboardingInfo, setOnboardingInfo]
  );

  const submitVerificationOnPress = useCallback(async () => {
    setSubmitting(true);
    await API.user.createPhoneVerificationRequest({
      uid,
      verificationInfo: onboardingInfo,
    });
    showNotificationBanner(
      dispatch,
      "Submitted Info",
      NotificationType.AFFIRMATIVE
    );
    if (isDesktop) {
      navigate(NavigationId.HOME_ACCOUNT_STATUS);
    } else {
      // This verification onboarding flow is only accessible via sign up onboarding on mobile flow
      navigate(NavigationId.ONBOARDING, {
        state: { defaultStage: OnboardingStage.FINISH_SETUP },
      });
    }
    setSubmitting(false);
  }, [dispatch, navigate, onboardingInfo, uid]);

  return (
    <>
      <div
        className="ColumnNormal"
        style={{ width: isDesktop ? 330 : undefined, gap: 24 }}
      >
        <DropdownMenu
          dropdownItems={dropdownItems}
          trigger={["click"]}
          placement="bottom"
          onOpenChange={(isOpen: boolean) => setDropdownVisible(isOpen)}
        >
          <div
            className="AlignedRowSpaced"
            style={{
              padding: 14,
              borderRadius: 14,
              border: `1px solid ${Colors.GRAY2}`,
            }}
          >
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <span className="bodyMedium">
                {onboardingInfo.messageVolume.toLocaleString()} texts/mo
              </span>
              <span className="smallBodySubtext">Estimated monthly volume</span>
            </div>
            <Icon
              icon={dropdownVisible ? "mdi-chevron-up" : "mdi:chevron-down"}
              height={20}
            />
          </div>
        </DropdownMenu>
        <RectangleButton
          buttonLabel="Continue"
          containerStyles={{ paddingInline: 10, paddingBlock: 14 }}
          altTextColor={Colors.WHITE}
          altColor={Colors.BLACK}
          loading={submitting}
          onPress={submitVerificationOnPress}
        />
      </div>
    </>
  );
};

export default PhoneOnboardingMessageVolume;
