import { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  accountActions,
  getAccountState,
  LoginState,
} from "../../redux/slices/accountSlice";
import { isDesktop } from "react-device-detect";
import TopHeader from "../../components/TopHeader";
import {
  hasSubscription,
  userPhoneVerificationStatus,
} from "@markit/common.utils";
import { useNavigate } from "react-router-dom";
import PhoneOnboardingSelectType from "../../components/Subscription/PhoneOnboarding/PhoneOnboardingSelectType";
import PhoneOnboardingEnterInfo from "../../components/Subscription/PhoneOnboarding/PhoneOnboardingEnterInfo";
import { firebaseMapsApiKey } from "../../firebase";
import { loadGoogleMapsScript } from "../../components/CreateEvent/CreateModals/CreateLocationModal";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import PhoneOnboardingMessageVolume from "../../components/Subscription/PhoneOnboarding/PhoneOnboardingMessageVolume";
import { Colors } from "../../utils/colors";
import MarkitLogoBlue from "../../assets/MarkitLogoBlue.png";
import { useTheme } from "../../hooks/useTheme";
import { Icon } from "@iconify/react";
import { useOnMount } from "../../hooks/useOnMount";
import LoadingScreen from "../LoadingScreen";
import { TwilioPhoneVerificationStatus } from "@markit/common.types";
import { NavigationId } from "../../navigation/AppParamList";

export enum PhoneOnboardingStage {
  SELECT_TYPE = "Select Type",
  ENTER_INFO = "Enter Info",
  MESSAGE_VOLUME = "Message Volume",
}

export enum VerificationAccountType {
  BUSINESS = "Business",
  INDIVIDUAL = "Individual",
}

export const messageVolumeOptions = [
  "10",
  "100",
  "1,000",
  "10,000",
  "100,000",
  "250,000",
  "500,000",
  "750,000",
  "1,000,000",
  "5,000,000",
  "10,000,000+",
] as const;

export type VerificationInfo = {
  businessCity: string;
  businessContactEmail: string;
  businessContactFirstName: string;
  businessContactLastName: string;
  businessContactPhone: string;
  businessName: string;
  businessPostalCode: string;
  businessStateProvinceRegion: string;
  businessStreetAddress: string;
  businessWebsite: string;
  messageVolume:
    | "10"
    | "100"
    | "1,000"
    | "10,000"
    | "100,000"
    | "250,000"
    | "500,000"
    | "750,000"
    | "1,000,000"
    | "5,000,000"
    | "10,000,000+";
};

const SubscriptionPhoneOnboarding = memo(
  function SubscriptionPhoneOnboardingFn() {
    const { accountData, appInitialized, loggedIn } =
      useSelector(getAccountState).account;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { theme } = useTheme();
    const [phoneOnboardingStage, setPhoneOnboardingStage] =
      useState<PhoneOnboardingStage>(PhoneOnboardingStage.SELECT_TYPE);
    const [selectedAccountType, setSelectedAccountType] =
      useState<VerificationAccountType>(VerificationAccountType.BUSINESS);
    const [onboardingInfo, setOnboardingInfo] = useState<VerificationInfo>({
      businessCity: "",
      businessContactEmail: accountData.email,
      businessContactFirstName: "",
      businessContactLastName: "",
      businessContactPhone: accountData.phoneNumber,
      businessName: accountData.fullName,
      businessPostalCode: "",
      businessStateProvinceRegion: "",
      businessStreetAddress: "",
      businessWebsite: "",
      messageVolume: "10",
    });
    const [loading, setLoading] = useState(true);
    const [loadingMaps, setLoadingMaps] = useState(true);

    const styles = {
      backButton: {
        backgroundColor: Colors.WHITE1,
        borderRadius: 100,
        height: 35,
        width: 35,
        border: `1px solid ${theme.DividerColor.borderColor}`,
        cursor: "pointer",
      },
    };

    useOnMount(() => {
      loadGoogleMapsScript(firebaseMapsApiKey, () => {
        setLoadingMaps(false);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useAsyncEffect(async () => {
      if (loggedIn !== LoginState.LOGGED_IN) {
        dispatch(accountActions.setRedirectPath(location.pathname));
        navigate(NavigationId.SIGN_IN);
        return;
      }
      if (appInitialized && loading) {
        if (!hasSubscription(accountData)) {
          alert("You do not have a subscription!");
          navigate("/home");
        }
        if (
          userPhoneVerificationStatus(accountData) !==
          TwilioPhoneVerificationStatus.UNVERIFIED
        ) {
          alert("You have already submitted info for your account.");
          navigate("/home");
        }
        setLoading(false);
      }
    }, [accountData, appInitialized, dispatch, loading, loggedIn, navigate]);

    const internalBackButton = useMemo(
      () => (
        <div style={isDesktop ? { position: "absolute", left: 20 } : {}}>
          <div
            className="Centering"
            style={styles.backButton}
            onClick={() =>
              setPhoneOnboardingStage(
                phoneOnboardingStage === PhoneOnboardingStage.ENTER_INFO
                  ? PhoneOnboardingStage.SELECT_TYPE
                  : PhoneOnboardingStage.ENTER_INFO
              )
            }
          >
            <Icon icon="ion:arrow-back" height={24} color={Colors.BLACK} />
          </div>
        </div>
      ),
      [phoneOnboardingStage, styles.backButton]
    );

    const renderContent = useMemo(
      () =>
        !loadingMaps ? (
          <div
            className={isDesktop ? "AlignedColumn" : "ColumnNormal"}
            style={{
              paddingInline: isDesktop ? 0 : 14,
              paddingTop: 40,
              paddingBottom: 100,
              gap: 30,
              position: "relative",
            }}
          >
            {phoneOnboardingStage !== PhoneOnboardingStage.SELECT_TYPE
              ? internalBackButton
              : null}
            <div
              className={isDesktop ? "AlignedColumn" : "ColumnNormal"}
              style={{ gap: 20 }}
            >
              <div>
                <img
                  style={{ height: 20, width: "auto", objectFit: "cover" }}
                  src={MarkitLogoBlue}
                  alt={"markitLogoBlue"}
                />
              </div>
              <div
                className={isDesktop ? "AlignedColumn" : "ColumnNormal"}
                style={{ gap: 12 }}
              >
                <span style={{ fontSize: 20, fontWeight: 500 }}>
                  {phoneOnboardingStage === PhoneOnboardingStage.SELECT_TYPE
                    ? "Continue as a business/organization or individual?"
                    : phoneOnboardingStage === PhoneOnboardingStage.ENTER_INFO
                    ? "Additional Account Details"
                    : "How many texts do you plan to send each month?"}
                </span>
                {phoneOnboardingStage !== PhoneOnboardingStage.SELECT_TYPE ? (
                  <span
                    style={{
                      fontSize: 12,
                      color: Colors.GRAY2,
                      textAlign: isDesktop ? "center" : "left",
                      width: isDesktop ? 320 : undefined,
                    }}
                  >
                    {phoneOnboardingStage ===
                    PhoneOnboardingStage.ENTER_INFO ? (
                      <span>
                        Enter the required information below to continue.
                        <br />
                        This additional step is required for all Markit+
                        accounts.
                      </span>
                    ) : (
                      "Select an option that best represents the number of texts you plan on sending each month."
                    )}
                  </span>
                ) : null}
              </div>
            </div>
            {phoneOnboardingStage === PhoneOnboardingStage.SELECT_TYPE ? (
              <PhoneOnboardingSelectType
                selectedAccountType={selectedAccountType}
                setSelectedAccountType={setSelectedAccountType}
                setOnboardingStage={setPhoneOnboardingStage}
              />
            ) : phoneOnboardingStage === PhoneOnboardingStage.ENTER_INFO ? (
              <PhoneOnboardingEnterInfo
                selectedAccountType={selectedAccountType}
                onboardingInfo={onboardingInfo}
                setOnboardingInfo={setOnboardingInfo}
                setOnboardingStage={setPhoneOnboardingStage}
              />
            ) : phoneOnboardingStage === PhoneOnboardingStage.MESSAGE_VOLUME ? (
              <PhoneOnboardingMessageVolume
                onboardingInfo={onboardingInfo}
                setOnboardingInfo={setOnboardingInfo}
              />
            ) : null}
          </div>
        ) : null,
      [
        loadingMaps,
        phoneOnboardingStage,
        internalBackButton,
        selectedAccountType,
        onboardingInfo,
      ]
    );

    if (loading) {
      return <LoadingScreen isLoadingPage={loading} />;
    }

    return isDesktop ? (
      <div className="ColumnNormal" style={{ height: "100vh" }}>
        <TopHeader hideLogin />
        {renderContent}
      </div>
    ) : (
      renderContent
    );
  }
);

export default SubscriptionPhoneOnboarding;
