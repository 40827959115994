import { Colors } from "../../../utils/colors";
import { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import { TabPanel } from "../../FullEventSubComponents/TabPanel";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import SwitchToggleItem from "../../SwitchToggleItem";
import { CircularProgress } from "@mui/material";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import {
  MembershipState,
  NotificationType,
  StripeAccountStatus,
} from "@markit/common.types";
import { API } from "../../../API";
import ConfirmActionModal from "../../Containers/ConfirmPopups/ConfirmActionModal";
import { connectToStripeAccount } from "../../../utils/stripeAccountUtils";
import { testStripe } from "../../FullEvent/FullEventInputForm";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import {
  disableMemberships,
  getNumPaidMembershipSubscriptions,
} from "../../../utils/subscriptionUtils";
import AlertContainer from "../../Containers/AlertContainer";
import { useOnMount } from "../../../hooks/useOnMount";
import MembershipSettingsPlanItem from "./MembershipSettingsPlanItem";
import {
  hasSubscription,
  hasSubscriptionUnlockedAdvancedData,
  MARKIT_DOCS,
} from "@markit/common.utils";
import TextHoverButton from "../../Buttons/TextHoverButton";

export type MembershipSettingsProps = {
  tabValue: number;
  setTabValue: (tabValue: number) => void;
  setListValue: (listValue: number) => void;
};

const MembershipSettings = (props: MembershipSettingsProps) => {
  const { tabValue, setTabValue, setListValue } = props;
  const { accountData, readyForPayment } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [enablePaidMemberships, setEnablePaidMemberships] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [confirmDisableMemberships, setConfirmDisableMemberships] =
    useState(false);
  const [alertText, setAlertText] = useState({
    header: "",
    subHeader: "",
  });

  const styles = {
    sectionContainer: {
      paddingBlock: 14,
      backgroundColor: Colors.WHITE,
    },
  };

  useOnMount(() => {
    if (accountData.membership.state === MembershipState.ACTIVE) {
      setEnablePaidMemberships(true);
    }
  });

  const hasSub = useMemo(() => hasSubscription(accountData), [accountData]);

  const hasAdvancedSub = useMemo(
    () => hasSubscriptionUnlockedAdvancedData(accountData),
    [accountData]
  );

  const userStripeAccountId = useMemo(
    () =>
      testStripe
        ? accountData.testStripeAccountId
        : accountData.stripeAccountId,
    [accountData.stripeAccountId, accountData.testStripeAccountId]
  );

  const userStripeAccountStatus = useMemo(
    () =>
      testStripe
        ? accountData.testStripeAccountStatus
        : accountData.stripeAccountStatus,
    [accountData.stripeAccountStatus, accountData.testStripeAccountStatus]
  );

  const stripeConnectStatus = useMemo(() => {
    if (userStripeAccountStatus === StripeAccountStatus.INCOMPLETE) {
      if (userStripeAccountId === "") {
        return StripeAccountStatus.INCOMPLETE;
      } else {
        return "finish connecting";
      }
    } else if (userStripeAccountStatus === StripeAccountStatus.PENDING) {
      return StripeAccountStatus.PENDING;
    } else {
      return StripeAccountStatus.COMPLETED;
    }
  }, [userStripeAccountId, userStripeAccountStatus]);

  const checkNumPaidMemberships = useCallback(async () => {
    const numPaidSubs = await getNumPaidMembershipSubscriptions(
      accountData.uid
    );
    return numPaidSubs;
  }, [accountData.uid]);

  const toggleMemberships = useCallback(async () => {
    if (!enablePaidMemberships) {
      setLoading(true);
      setEnablePaidMemberships(true);
      showNotificationBanner(
        dispatch,
        "Enabled Memberships!",
        NotificationType.AFFIRMATIVE
      );
      await API.user.initializeMembershipPlans({ uid: accountData.uid });
      setLoading(false);
    } else {
      const numPaidSubscriptions = await checkNumPaidMemberships();
      if (numPaidSubscriptions > 0) {
        setAlertText({
          header: "You cannot turn off paid memberships.",
          subHeader:
            "You have one or more paid subscribers. Contact support if you need help.",
        });
      } else {
        setConfirmDisableMemberships(true);
      }
    }
  }, [
    accountData.uid,
    checkNumPaidMemberships,
    dispatch,
    enablePaidMemberships,
  ]);

  const deleteMembershipPlans = useCallback(async () => {
    await disableMemberships(accountData.uid, accountData.membership);
    showNotificationBanner(
      dispatch,
      "Disabled Memberships",
      NotificationType.AFFIRMATIVE
    );
    setEnablePaidMemberships(false);
    setConfirmDisableMemberships(false);
  }, [accountData.membership, accountData.uid, dispatch]);

  const viewMembershipPlanSubscribers = useCallback(
    (membershipId: string) => {
      setTabValue(0);
      setListValue(membershipId === "free" ? 1 : 0);
    },
    [setListValue, setTabValue]
  );

  const manageStripe = useCallback(async () => {
    setConnecting(true);
    await connectToStripeAccount(accountData.uid);
    setConnecting(false);
  }, [accountData.uid]);

  return (
    <TabPanel value={tabValue} index={1}>
      <div
        className="ColumnNormal"
        style={{ width: 544, marginInline: "auto", gap: 20 }}
      >
        <StandardBorderedContainer containerStyles={styles.sectionContainer}>
          <div className="ColumnNormal" style={{ gap: 4, paddingInline: 14 }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <span className="sectionTitle">Paid Memberships</span>
              <Icon
                icon={"ion:ribbon"}
                height={18}
                style={{ color: Colors.BLACK }}
              />
            </div>
            <span className="smallBodySubtext">
              Make money from texting. Charge monthly and annual fees from
              subscribers.
            </span>
          </div>
          <HorizontalDivider altMargin={14} />
          <div style={{ paddingInline: 14 }}>
            <SwitchToggleItem
              title="Enable Paid Memberships"
              description="Enable and connect to Stripe to enable paid memberships"
              toggleValue={enablePaidMemberships}
              onChange={toggleMemberships}
              disabled={!readyForPayment}
            />
          </div>
          <HorizontalDivider altMargin={14} />
          <div
            className="AlignedRowSpacedSelect"
            style={{
              paddingInline: 14,
              pointerEvents: !hasAdvancedSub ? "none" : "all",
              opacity: !hasAdvancedSub ? 0.4 : 1,
            }}
            onClick={manageStripe}
          >
            <div className="AlignedRowSelect" style={{ gap: 14 }}>
              <Icon
                icon="fa6-brands:cc-stripe"
                height={25}
                style={{ color: Colors.PURPLE11 }}
              />
              <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                <span className="bodyMedium">
                  {!readyForPayment
                    ? `${
                        stripeConnectStatus === "finish connecting"
                          ? "Finish Connecting"
                          : "Connect to Stripe"
                      } to Enable Paid Plans`
                    : "Manage Stripe Account"}
                </span>
                <div className="ColumnNormal" style={{ gap: 2 }}>
                  <span className="smallBodySubtext">
                    Markit takes 10% transaction fee not including stripe
                    processing fees.
                  </span>
                  <TextHoverButton
                    text="Learn more about fees"
                    onPress={(e) => {
                      e?.stopPropagation();
                      window.open(MARKIT_DOCS + "/membershipfees");
                    }}
                    icon={
                      <Icon
                        icon="ion:chevron-forward"
                        height={12}
                        color={Colors.GRAY1}
                        style={{ marginBottom: -2 }}
                      />
                    }
                    iconPosition="right"
                  />
                </div>
              </div>
            </div>
            {connecting ? (
              <CircularProgress size={18} style={{ color: Colors.GRAY1 }} />
            ) : (
              <Icon icon="mdi:chevron-right" height={24} color={Colors.GRAY1} />
            )}
          </div>
        </StandardBorderedContainer>
        <span className="sectionTitle">Membership Plans</span>
        <div className="ColumnNormal">
          {loading ? (
            <div className="Centering" style={{ paddingBlock: 80 }}>
              <CircularProgress style={{ color: Colors.BLACK }} size={24} />
            </div>
          ) : hasAdvancedSub &&
            accountData.membership.state === MembershipState.ACTIVE &&
            accountData.membership.membershipPlans.length > 0 ? (
            <div
              className="ColumnNormal"
              style={{ gap: 14, paddingBottom: 80 }}
            >
              {accountData.membership.membershipPlans.map((membership) => (
                <MembershipSettingsPlanItem
                  key={membership.id}
                  membershipPlan={membership}
                  viewPlanSubscribers={viewMembershipPlanSubscribers}
                />
              ))}
            </div>
          ) : (
            <EmptyStateButton
              title={
                !hasAdvancedSub
                  ? `${hasSub ? "Upgrade" : "Get"} Markit+ to Enable Paid Plans`
                  : !readyForPayment
                  ? `${
                      stripeConnectStatus === "finish connecting"
                        ? "Finish Connecting"
                        : "Connect Stripe"
                    } to Enable Paid Plans`
                  : "Enable Paid Plans to Start Earning"
              }
              description={
                !hasAdvancedSub
                  ? `${
                      hasSub ? "Upgrade" : "Get"
                    } Markit+ to start earning with paid subscription plans`
                  : "Your free and paid plans will show up here once you connect to Stripe and enable paid plans."
              }
              icon={
                <Icon
                  icon={
                    readyForPayment || !hasAdvancedSub
                      ? "ion:ribbon"
                      : "fa6-brands:cc-stripe"
                  }
                  height={50}
                  style={{
                    color:
                      readyForPayment || !hasAdvancedSub
                        ? Colors.GREEN2
                        : Colors.PURPLE11,
                  }}
                />
              }
              iconBox={80}
              iconBackgroundColor={Colors.WHITE}
              btnText={
                !hasAdvancedSub
                  ? `${hasSub ? "Upgrade" : "Get Markit+"}`
                  : !readyForPayment
                  ? stripeConnectStatus === "finish connecting"
                    ? "Finish Connecting"
                    : "Connect to Stripe"
                  : "Enable Paid Plans"
              }
              onPress={!readyForPayment ? manageStripe : toggleMemberships}
              containerStyles={{ paddingBlock: 80 }}
              buttonContainerStyles={{
                backgroundColor: !readyForPayment
                  ? Colors.BLACK
                  : Colors.GREEN2,
              }}
            />
          )}
        </div>
      </div>
      <ConfirmActionModal
        heading={"Turn Off Paid Memberships?"}
        subtext={"Are you sure you want turn off paid memberships?"}
        confirmButtonText="Yes"
        hideModal={!confirmDisableMemberships}
        setIsVisible={setConfirmDisableMemberships}
        confirmOnPress={deleteMembershipPlans}
      />
      <AlertContainer
        headerComp={alertText.header}
        subHeaderComp={alertText.subHeader}
        closeModal={() => setAlertText({ header: "", subHeader: "" })}
        hideModal={alertText.header === ""}
      />
    </TabPanel>
  );
};

export default MembershipSettings;
