import "../../css/Login.css";
import {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import TopHeader from "../../components/TopHeader";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import {
  LoginState,
  accountActions,
  finishInitialStartup,
  getAccountState,
  toggleInCreatorMode,
} from "../../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { MixpanelContext } from "../../context/AnalyticsService";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { DataLoaders } from "../../redux/slices/dataSlice";
import { useNavigate } from "../../hooks/useNavigate";
import LoginOnboardingProfile from "./LoginOnboardingProfile";
import { usePartialAccountUpdates } from "../../hooks/usePartialAccountUpdates";
import LoginOnboardingAccountType from "../../components/Login/LoginOnboardingAccountType";
import { useOnMount } from "../../hooks/useOnMount";
import LoginWelcomeBody from "../../components/Login/LoginWelcomeBody";
import OSPills from "../../components/OSPills";
import { NavigationId } from "../../navigation/AppParamList";
import SubscriptionPlansFlow from "../../components/Subscription/SubscriptionPlansFlow";
import { hasSubscription } from "@markit/common.utils";

export enum OnboardingStage {
  ONBOARD = "Onboarding",
  PROFILE_SETUP = "Profile Setup",
  CHOOSE_PLAN = "Choose Plan",
  FINISH_SETUP = "Finish Setup",
}

const LoginOnboarding = memo(function LoginOnboardingFn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const { account } = useSelector(getAccountState);
  const { accountData, currentRoleTicket, loggedIn } = account;
  const { defaultStage } = useLocation().state || {}; // acceleratedSetup = start on profile setup

  // some cases we want to start user off without asking first questions
  const [onboardingStage, setOnboardingStage] = useState<OnboardingStage>(
    defaultStage || OnboardingStage.ONBOARD
  );
  const [loading, setLoading] = useState(false);

  const { accountSettings, updateAccountSettings } = usePartialAccountUpdates();

  const styles = {
    itemHeader: {
      fontSize: 16,
      fontWeight: "500",
    },
    itemDescription: {
      color: Colors.GRAY2,
      marginTop: 5,
      fontSize: 12,
    },
    labelView: { marginBottom: 7 },
    labelText: { fontSize: 12, fontWeight: "600", color: Colors.BLACK },
    labelTagText: { fontSize: 12, fontWeight: "600", color: Colors.GRAY2 },
    labelTagTextError: {
      fontSize: 12,
      fontWeight: "600",
      color: theme.ErrorBorderColor.borderColor,
    },
  };

  useOnMount(() => {
    if (loggedIn !== LoginState.LOGGED_IN) {
      navigate(NavigationId.WELCOME);
      return;
    }
    if (!defaultStage && accountSettings.email !== "") {
      setOnboardingStage(OnboardingStage.PROFILE_SETUP);
    }
  });

  const creatorOnPress = useCallback(() => {
    if (!accountData.inCreatorMode) {
      updateAccountSettings({ inCreatorMode: true });
      dispatch(toggleInCreatorMode(accountData.uid, false));
    }
    setOnboardingStage(OnboardingStage.PROFILE_SETUP);
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    dispatch,
    updateAccountSettings,
  ]);

  const subscriberOnPress = useCallback(() => {
    setLoading(true);
    updateAccountSettings({ inCreatorMode: false });
    // function sets creator mode to opposite of second parameter (since it's switching it)
    dispatch(toggleInCreatorMode(accountData.uid, true));
    setOnboardingStage(OnboardingStage.PROFILE_SETUP);
    setLoading(false);
  }, [accountData.uid, dispatch, updateAccountSettings]);

  const logSignUp = useCallback(async () => {
    setLoading(true);
    dispatch(finishInitialStartup(accountData, mixpanel));
    setLoading(false);
  }, [accountData, dispatch, mixpanel]);

  // Redirect the user to the confirmation page to accept their event role
  const redirectAcceptRole = useCallback(async () => {
    // for role redeem, don't show last screen
    if (currentRoleTicket) {
      await logSignUp();
      // Take user to confirmation page to accept role after sign up
      window.location.replace(
        `https://markitai.com/e/${currentRoleTicket.eventId}/i/${accountData.uid}?ticketId=${currentRoleTicket.id}`
      );
      setTimeout(
        () => dispatch(accountActions.setCurrentRoleTicket(undefined)),
        500
      );
    }
  }, [accountData.uid, currentRoleTicket, dispatch, logSignUp]);

  const finalContinueOnPress = useCallback(async () => {
    if (isDesktop) {
      navigate(NavigationId.HOME_GET_STARTED, {
        state: { showSkipOnboarding: true },
      });
    } else {
      navigate("/home");
    }
  }, [navigate]);

  const finishProfileSetUp = useCallback(async () => {
    if (accountSettings.inCreatorMode) {
      await logSignUp();
      setOnboardingStage(OnboardingStage.CHOOSE_PLAN);
      window.scrollTo(0, 0);
    } else {
      if (currentRoleTicket) {
        await redirectAcceptRole();
      } else {
        dispatch(DataLoaders.user(accountSettings.uid));
        navigate(`/u/${accountSettings.username}`, {
          state: { username: accountSettings.username },
        });
      }
    }
  }, [
    accountSettings.inCreatorMode,
    accountSettings.uid,
    accountSettings.username,
    currentRoleTicket,
    dispatch,
    logSignUp,
    navigate,
    redirectAcceptRole,
  ]);

  const renderItemRow = useCallback(
    (
      title: string,
      description: string | ReactNode,
      showLine: boolean,
      showCheck?: boolean
    ) => (
      <div
        className="AlignedRow"
        style={{ alignItems: "flex-start", opacity: showCheck ? 0.6 : 1 }}
      >
        <div className="ColumnNormal" style={{ alignItems: "center" }}>
          <Icon
            icon={
              showCheck
                ? "ion:checkmark-circle"
                : "mdi:checkbox-blank-circle-outline"
            }
            height={16}
            color={showCheck ? Colors.GREEN2 : Colors.GRAY2}
          />
          {showLine ? (
            <div
              style={{
                height: 60,
                width: 1,
                backgroundColor: Colors.GRAY2,
              }}
            />
          ) : null}
        </div>
        <div
          className="ColumnNormal"
          style={{ paddingLeft: 14, width: isDesktop ? undefined : 280 }}
        >
          <span
            style={{
              ...styles.itemHeader,
              color: showCheck ? Colors.GRAY2 : Colors.BLACK,
            }}
          >
            {title}
          </span>
          <span style={styles.itemDescription}>{description}</span>
        </div>
      </div>
    ),
    [styles.itemDescription, styles.itemHeader]
  );

  const renderFinishSetup = useMemo(
    () => (
      <LoginWelcomeBody
        title="You're all set!"
        subtext={`Welcome to Markit ${accountData.fullName}!`}
        alternateIcon={<span style={{ fontSize: 35 }}>🥳</span>}
      >
        <div className="ColumnNormal" style={{ gap: 30, width: "100%" }}>
          <div
            className="ColumnNormal"
            style={{ margin: isDesktop ? "auto" : undefined }}
          >
            {renderItemRow(
              "Create your account",
              "You just did this! Edit your profile anytime.",
              true,
              true
            )}
            {hasSubscription(accountData) &&
              renderItemRow(
                "Enter additional information",
                "Required for all Markit+ users to complete to access their account",
                true,
                true
              )}
            {currentRoleTicket ? (
              renderItemRow(
                "Accept your event role",
                "After this, you can manage the event.",
                false
              )
            ) : isDesktop ? (
              <>
                {renderItemRow(
                  "Customize welcome flow",
                  "Explore Markit and grow subscribers",
                  true
                )}
                {renderItemRow(
                  "Start texting your subscribers!",
                  "Send your first text",
                  false,
                  false
                )}
              </>
            ) : (
              renderItemRow(
                "Use Markit on desktop or through our mobile app for full functionality",
                <div className="ColumnNormal" style={{ gap: 14 }}>
                  <span>Explore Markit and grow subscribers</span>
                  <div style={{ width: 198 }}>
                    <OSPills forceLight={true} />
                  </div>
                </div>,
                false,
                false
              )
            )}
          </div>
          <RectangleButton
            buttonLabel={
              <span>
                {currentRoleTicket ? "Go to Accept Role" : "Continue to markit"}
              </span>
            }
            onPress={
              currentRoleTicket ? redirectAcceptRole : finalContinueOnPress
            }
            altColor={Colors.BLACK}
            altTextColor={Colors.WHITE}
            altPaddingVert={12}
            disabled={loading}
            loading={loading}
          />
        </div>
      </LoginWelcomeBody>
    ),
    [
      accountData,
      renderItemRow,
      currentRoleTicket,
      redirectAcceptRole,
      finalContinueOnPress,
      loading,
    ]
  );

  return (
    <div
      className="LoginContainer"
      style={{
        height:
          onboardingStage === OnboardingStage.PROFILE_SETUP ? "auto" : "100vh",
      }}
    >
      {isDesktop ? <TopHeader hideLogin /> : null}
      {onboardingStage === OnboardingStage.CHOOSE_PLAN ? (
        <SubscriptionPlansFlow
          onboardingFreeOnPress={() =>
            setOnboardingStage(OnboardingStage.FINISH_SETUP)
          }
        />
      ) : (
        <div
          className="ColumnNormal"
          style={{
            margin: isDesktop ? "auto" : undefined,
            gap: 30,
            width: isDesktop ? 544 : undefined,
            marginTop: isDesktop ? 40 : 60,
            paddingBottom:
              onboardingStage === OnboardingStage.PROFILE_SETUP ? 100 : 0,
          }}
        >
          {onboardingStage === OnboardingStage.PROFILE_SETUP ? (
            <LoginOnboardingProfile
              accountSettings={accountSettings}
              updateAccountSettings={updateAccountSettings}
              loading={loading}
              onFinishSetUp={finishProfileSetUp}
            />
          ) : onboardingStage === OnboardingStage.FINISH_SETUP ? (
            renderFinishSetup
          ) : (
            <LoginOnboardingAccountType
              creatorOnPress={creatorOnPress}
              subscriberOnPress={subscriberOnPress}
            />
          )}
        </div>
      )}
    </div>
  );
});

export default LoginOnboarding;
