import { CSSProperties, MouseEvent, ReactNode } from "react";

type TextHoverButtonProps = {
  text: string;
  onPress: (e?: MouseEvent) => void;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  textStyles?: CSSProperties;
};

// Any text that is clickable and we want to show hover effect on
const TextHoverButton = (props: TextHoverButtonProps) => {
  const { text, onPress, icon, iconPosition = "left", textStyles } = props;

  return (
    <div
      className="AlignedRowSelect"
      onClick={(e) => onPress(e)}
      style={{ gap: 4 }}
      onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.7")}
      onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
    >
      {iconPosition === "left" && icon}
      <span style={textStyles} className="smallBodySubtext">
        {text}
      </span>
      {iconPosition === "right" && icon}
    </div>
  );
};

export default TextHoverButton;
