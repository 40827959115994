import "../../css/GlobalStyles.css";
import { Colors } from "../../utils/colors";
import { PlanTypeV4, SubscriptionPlanType } from "@markit/common.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import RectangleButton from "../Buttons/RectangleButton";
import {
  hasSubscription,
  MARKIT_HOTLINE_NUMBER,
  mostRecentSubscription,
  mostRecentSubscriptionPlan,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useLocation } from "react-router-dom";
import { useTheme } from "../../hooks/useTheme";
import { Icon } from "@iconify/react";
import { checkIsDesktop } from "../../utils/screenResponsivenessUtils";
import { NavigationId } from "../../navigation/AppParamList";

type SubscriptionPlansProps = {
  selectedCardOption: number;
  setSelectedCardOption: (selectedCardOption: number) => void;
  itemOnPress: (cardOption: number) => void;
  homePage?: boolean;
};

const SubscriptionPlans = (props: SubscriptionPlansProps) => {
  const { itemOnPress, selectedCardOption, setSelectedCardOption, homePage } =
    props;
  const { theme } = useTheme();
  const accountData = useSelector(getAccountState).account.accountData;
  const { changePlan } = useLocation().state || {};
  const pathname = useLocation().pathname;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Add useEffect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isDesktopSize = useMemo(
    () => checkIsDesktop(windowWidth),
    [windowWidth]
  );

  const isOnboarding = useMemo(
    () => pathname === NavigationId.ONBOARDING,
    [pathname]
  );

  const subscriptionPlanItems = [
    PlanTypeV4.FREE,
    PlanTypeV4.BASIC,
    PlanTypeV4.CREATOR,
    PlanTypeV4.BUSINESS,
  ];

  const mostRecentSubPlan = useMemo(
    () =>
      mostRecentSubscriptionPlan(accountData) ||
      (!isOnboarding && !homePage ? PlanTypeV4.FREE : undefined),
    [accountData, homePage, isOnboarding]
  );

  const subscriptionCard = useMemo(
    () =>
      selectedCardOption === 1
        ? PlanTypeV4.BASIC
        : selectedCardOption === 2
        ? PlanTypeV4.CREATOR
        : selectedCardOption === 3
        ? PlanTypeV4.BUSINESS
        : PlanTypeV4.FREE,
    [selectedCardOption]
  );

  const mostRecentPriceAmount = useMemo(() => {
    const subscription = mostRecentSubscription(accountData);
    if (subscription) {
      return subscription.price[0].amount;
    }
    return 0;
  }, [accountData]);

  const renderButtonTitle = useCallback(
    (plan: SubscriptionPlanType) => {
      if (changePlan) {
        if (mostRecentPriceAmount > plan.price) {
          return "Downgrade to " + plan.name;
        } else if (mostRecentPriceAmount < plan.price) {
          return "Upgrade to " + plan.name;
        } else {
          return "Current Plan";
        }
      } else if (
        !homePage &&
        hasSubscription(accountData) &&
        plan === mostRecentSubPlan
      ) {
        return "Current Plan";
      } else {
        return "Continue with " + (plan.price === 0 ? "Free" : plan.name);
      }
    },
    [
      accountData,
      changePlan,
      homePage,
      mostRecentPriceAmount,
      mostRecentSubPlan,
    ]
  );

  const buttonColor = useCallback(
    (isCurrentPlan: boolean, index: number) =>
      homePage
        ? selectedCardOption === index || isCurrentPlan
          ? Colors.WHITE
          : Colors.TRANSPARENT
        : selectedCardOption === index || isCurrentPlan || !isDesktopSize
        ? Colors.BLACK
        : Colors.TRANSPARENT,
    [homePage, isDesktopSize, selectedCardOption]
  );

  const buttonTextColor = useCallback(
    (isCurrentPlan: boolean, index: number) =>
      homePage
        ? selectedCardOption === index || isCurrentPlan
          ? Colors.BLACK
          : Colors.WHITE
        : selectedCardOption === index || isCurrentPlan
        ? Colors.WHITE
        : Colors.BLACK,
    [homePage, selectedCardOption]
  );

  const buttonBorderColor = useCallback(
    (isCurrentPlan: boolean) =>
      isCurrentPlan
        ? `1px solid ${theme.DividerColor.borderColor}`
        : `1px solid ${homePage ? Colors.WHITE : Colors.BLACK}`,
    [homePage, theme.DividerColor.borderColor]
  );

  const renderMobilePlansView = useCallback(
    (subscriptionCard: SubscriptionPlanType) => {
      // If on home page and viewing in mobile view, preselect the creator plan
      const preselectedMobilePlan =
        homePage && subscriptionCard.name === PlanTypeV4.CREATOR.name;
      const isCurrentPlan =
        !homePage &&
        hasSubscription(accountData) &&
        mostRecentSubPlan.name === subscriptionCard.name;
      return (
        <SubscriptionPlanCard
          cardButton={
            <RectangleButton
              buttonLabel={renderButtonTitle(subscriptionCard)}
              onPress={() => itemOnPress(selectedCardOption)}
              altColor={buttonColor(
                isCurrentPlan,
                preselectedMobilePlan ? 2 : -1
              )}
              altTextColor={
                preselectedMobilePlan
                  ? Colors.BLACK
                  : homePage || !isDesktopSize
                  ? Colors.WHITE
                  : Colors.BLACK
              }
              disabled={isCurrentPlan}
              containerStyles={{
                border: buttonBorderColor(isCurrentPlan),
              }}
              altPaddingHorz={14}
              altPaddingVert={14}
            />
          }
          isSelected={isCurrentPlan}
          subscriptionPlan={subscriptionCard}
          homePage={homePage}
        />
      );
    },
    [
      accountData,
      buttonBorderColor,
      buttonColor,
      homePage,
      isDesktopSize,
      itemOnPress,
      mostRecentSubPlan,
      renderButtonTitle,
      selectedCardOption,
    ]
  );

  return (
    <div className="ColumnNormal">
      {isDesktopSize ? (
        <div className="AlignedRow" style={{ gap: 24 }}>
          {subscriptionPlanItems.map((subscriptionPlan, index) => {
            const isCurrentPlan =
              !homePage &&
              hasSubscription(accountData) &&
              mostRecentSubPlan.name === subscriptionPlan.name;
            return (
              <div style={{ flex: 1 }} key={index}>
                <SubscriptionPlanCard
                  cardButton={
                    <RectangleButton
                      buttonLabel={
                        <span style={{ fontWeight: 400 }}>
                          {renderButtonTitle(subscriptionPlan)}
                        </span>
                      }
                      onPress={() => itemOnPress(index)}
                      altColor={buttonColor(isCurrentPlan, index)}
                      altTextColor={buttonTextColor(isCurrentPlan, index)}
                      containerStyles={{
                        padding: 14,
                        border: buttonBorderColor(isCurrentPlan),
                      }}
                      disabled={isCurrentPlan}
                    />
                  }
                  subscriptionPlan={subscriptionPlan}
                  isSelected={selectedCardOption === index}
                  selectOnPress={
                    homePage ? undefined : () => setSelectedCardOption(index)
                  }
                  homePage={homePage}
                />
              </div>
            );
          })}
        </div>
      ) : homePage ? (
        <div className="ColumnNormal" style={{ gap: 20 }}>
          {subscriptionPlanItems.map((item) => renderMobilePlansView(item))}
        </div>
      ) : (
        renderMobilePlansView(subscriptionCard)
      )}
      <div className="ColumnCentering" style={{ paddingTop: 24 }}>
        <span
          style={{
            color: homePage ? Colors.WHITE : Colors.GRAY1,
            fontSize: 14,
            textAlign: "center",
            width: isDesktopSize ? "100%" : "70%",
          }}
        >
          For faster send times and fully customized enterprise solutions{" "}
          <a
            href={`sms:${MARKIT_HOTLINE_NUMBER}`}
            style={{ color: homePage ? Colors.BLUE5 : Colors.BLACK }}
          >
            Get in touch
          </a>
          <Icon
            icon="ion:chevron-right"
            height={10}
            color={homePage ? Colors.BLUE5 : Colors.BLACK}
            style={{ marginBottom: -1, marginLeft: 1 }}
          />
        </span>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
