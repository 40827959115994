import { Colors } from "../../../utils/colors";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import PreviewBoxContainer from "../../Containers/PreviewBoxContainer";
import { useTheme } from "../../../hooks/useTheme";
import RectangleButton from "../../Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { onCampaignNavigatePath } from "../../../utils/navigationUtils";
import { TabPanel } from "../../FullEventSubComponents/TabPanel";
import ShareMarkitNumber from "../../Subscription/ShareMarkitNumber";
import {
  hasSubscription,
  isPresetNoRecipientsAutomations,
  NON_SUBSCRIBER_REPLY_CAMPAIGN_ID,
  NON_SUBSCRIBER_REPLY_CAMPAIGN_NAME,
  userPhoneVerificationStatus,
} from "@markit/common.utils";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import { getCampaignState } from "../../../redux/slices/campaignSlice";
import { notificationActions } from "../../../redux/slices/notificationSlice";
import { TwilioPhoneVerificationStatus } from "@markit/common.types";
import VerificationAccountStatusItem from "../VerificationAccountStatusItem";

export type ProfileShareNumberProps = { tabValue: number };

const ProfileShareNumber = (props: ProfileShareNumberProps) => {
  const { tabValue } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { campaigns, campaignDetails } = useSelector(getCampaignState);
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const nonSubscriberReplyCampaign = useMemo(
    () =>
      campaigns.find(
        (campaign) => campaign.id === NON_SUBSCRIBER_REPLY_CAMPAIGN_ID
      ),
    [campaigns]
  );

  const message = useMemo(() => {
    const nonSubscriberReplyCampaignDetails = campaignDetails.find(
      (detail) => detail.campaignId === nonSubscriberReplyCampaign?.id
    );
    if (nonSubscriberReplyCampaignDetails) {
      return nonSubscriberReplyCampaignDetails.massTextDetails[0].massText
        .message;
    }
    return "";
  }, [campaignDetails, nonSubscriberReplyCampaign?.id]);

  const notApprovedAccountStatus = useMemo(
    () =>
      userPhoneVerificationStatus(accountData) !==
      TwilioPhoneVerificationStatus.APPROVED,
    [accountData]
  );

  const editNonSubscriberText = useCallback(() => {
    if (nonSubscriberReplyCampaign) {
      navigate(onCampaignNavigatePath(nonSubscriberReplyCampaign.id), {
        state: {
          campaignId: nonSubscriberReplyCampaign.id,
          fromProfileTabIndex: 2,
        },
      });
    } else {
      alert("Unable to edit the text at this time.");
    }
  }, [nonSubscriberReplyCampaign, navigate]);

  return (
    <TabPanel value={tabValue} index={2}>
      {hasSubscription(accountData) ? (
        <div
          className="ColumnNormal"
          style={{ gap: 20, width: 544, margin: "auto", paddingBottom: 100 }}
        >
          <ShareMarkitNumber />
          <PreviewBoxContainer
            message={message || "Unable to display the text at this time."}
            alternateBodyComp={
              notApprovedAccountStatus ? (
                <div style={{ paddingInline: 14 }}>
                  <VerificationAccountStatusItem expanded />
                </div>
              ) : undefined
            }
            containerStyles={{
              borderRadius: 12,
              backgroundColor: Colors.WHITE,
              border: `1.5px solid ${theme.DividerColor.borderColor}`,
            }}
            headerComp={
              <span className="sectionTitle">
                {NON_SUBSCRIBER_REPLY_CAMPAIGN_NAME}
              </span>
            }
            subHeaderComp={
              <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
                Sent when a non-subscriber texts your Markit number directly
              </span>
            }
            disablePlugins={
              nonSubscriberReplyCampaign &&
              isPresetNoRecipientsAutomations(nonSubscriberReplyCampaign.id)
            }
            headerRightComp={
              <RectangleButton
                buttonLabel="Edit"
                onPress={editNonSubscriberText}
                disabled={notApprovedAccountStatus}
                altBorderRadius={13}
                altPaddingHorz={12}
                altPaddingVert={12}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                iconRight={
                  <Icon
                    icon={"ion:chevron-forward"}
                    height={16}
                    style={{
                      color: notApprovedAccountStatus
                        ? Colors.GRAY2
                        : Colors.WHITE,
                    }}
                  />
                }
              />
            }
          />
        </div>
      ) : (
        <EmptyStateButton
          title={"Get Markit+ for Unique Number"}
          description={
            "Markit+ gives you a unique phone number. Customize direct texts to this number, get custom contact cards, and more."
          }
          icon={
            <Icon
              icon={"ion:call"}
              height={39}
              style={{ color: Colors.BLUE5 }}
            />
          }
          iconBox={60}
          iconBackgroundColor={Colors.WHITE}
          btnText={"Get Markit+"}
          onPress={() => dispatch(notificationActions.setMarkitPlusModal(true))}
          containerStyles={{ paddingTop: 120 }}
        />
      )}
    </TabPanel>
  );
};

export default ProfileShareNumber;
