import "../../css/Subscription.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  MARKIT_HOTLINE_NUMBER,
  isBusinessSubscription,
  isSubscriptionPaymentFailed,
  mostRecentSubscription,
  mostRecentSubscriptionPlan,
} from "@markit/common.utils";
import { GetNumericDate } from "../../utils/GetTime";
import SubscriptionDetailsOption from "./SubscriptionDetailsOption";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { PlanTypeV4 } from "@markit/common.types";
import PaymentFailedButtonModal from "../DisplayModal/UpdatePaymentInfoModal";
import { Colors } from "../../utils/colors";
import AlertContainer from "../Containers/AlertContainer";
import { useNavigate } from "../../hooks/useNavigate";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import PaymentFailedButton from "./PaymentFailedButton";

const SubscriptionDetails = memo(function SubscriptionDetailsFn() {
  const navigate = useNavigate();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);

  const styles = {
    bodyMediumBold: {
      fontSize: 14,
      fontWeight: 500,
    },
    placeholder: {
      fontSize: 12,
      color: Colors.GRAY2,
    },
  };

  const [alertText, setAlertText] = useState({
    heading: "",
    subHeading: "",
  });

  const mostRecentSub = useMemo(
    () => mostRecentSubscription(accountData),
    [accountData]
  );
  const subscriptionPlan = useMemo(
    () => mostRecentSubscriptionPlan(accountData),
    [accountData]
  );

  const isFreeSubscription = useMemo(
    () => accountData.customer.phone === "",
    [accountData.customer.phone]
  );

  const downgradeSubscriptionPlan = useMemo(() => {
    const mostRecentPriceId = mostRecentSub ? mostRecentSub.price[0].id : "";
    if (
      accountData.customer.phone !== "" &&
      accountData.customer.subscriptionSchedule
    ) {
      if (
        mostRecentPriceId === PlanTypeV4.CREATOR.priceId ||
        mostRecentPriceId === PlanTypeV4.CREATOR.testPriceId
      ) {
        return PlanTypeV4.BASIC;
      } else if (
        mostRecentPriceId === PlanTypeV4.BUSINESS.priceId ||
        mostRecentPriceId === PlanTypeV4.BUSINESS.testPriceId
      ) {
        return PlanTypeV4.CREATOR;
      }
    }
  }, [
    accountData.customer.phone,
    accountData.customer.subscriptionSchedule,
    mostRecentSub,
  ]);

  const frozenSubscription = useMemo(
    () => accountData.customer.state === "FROZEN",
    [accountData]
  );
  const trialSubscription = useMemo(
    () => accountData.customer.state === "TRIAL",
    [accountData]
  );
  const cancelSubscriptionAtEnd = useMemo(
    () => accountData.customer.cancelAtEnd,
    [accountData]
  );

  const navigateChangePaymentOnPress = useCallback(() => {
    setAlertText({
      heading: "Change payment feature coming soon...",
      subHeading: "",
    });
  }, []);

  const navigateUpgradeOnPress = useCallback(() => {
    if (downgradeSubscriptionPlan) {
      setAlertText({
        heading: "Cannot change subscription plan.",
        subHeading: "Downgrading currently in progress.",
      });
      return;
    }
    if (isBusinessSubscription(accountData)) {
      setAlertText({
        heading: "Contact Sales",
        subHeading: `Reach out to ${formatPhoneNumber(
          MARKIT_HOTLINE_NUMBER
        )} for help with changing your plan.`,
      });
      return;
    }
    navigate("/subscription", { state: { changePlan: true } });
    window.scrollTo(0, 0);
  }, [accountData, downgradeSubscriptionPlan, navigate]);

  const navigateFreezeOnPress = useCallback(() => {
    // Temp until freeze subscription logic is fully handled
    setAlertText({
      heading: `Contact support at ${formatPhoneNumber(
        MARKIT_HOTLINE_NUMBER
      )} regarding freezing your subscription.`,
      subHeading: "",
    });
    // if (downgradeSubscriptionPlan) {
    //   setAlertText({
    //     heading: "Cannot freeze subscription plan.",
    //     subHeading: "Downgrading currently in progress.",
    //   });
    //   return;
    // }
    // navigate("/subscription/freeze");
    // window.scrollTo(0, 0);
  }, []);

  const navigateCancelOnPress = useCallback(() => {
    if (downgradeSubscriptionPlan) {
      setAlertText({
        heading: "Cannot cancel subscription plan.",
        subHeading: "Downgrading currently in progress.",
      });
      return;
    }
    navigate("/subscription/cancel");
    window.scrollTo(0, 0);
  }, [downgradeSubscriptionPlan, navigate]);

  return (
    <>
      <StandardBorderedContainer
        containerStyles={{
          backgroundColor: Colors.WHITE,
          paddingBlock: 14,
          borderRadius: 20,
        }}
      >
        <div className="AlignedRow" style={{ gap: 10, paddingLeft: 14 }}>
          <span style={{ fontWeight: 500 }}>Account Billing</span>
          <Icon icon={"ion:card"} height={20} color={Colors.BLACK} />
        </div>
        <hr />
        {subscriptionPlan ? (
          <div className="ColumnNormal" style={{ paddingInline: 14 }}>
            {isSubscriptionPaymentFailed(accountData.customer.state) ? (
              <PaymentFailedButtonModal
                isVisible={paymentFailedVisible}
                setIsVisible={setPaymentFailedVisible}
                button={
                  <PaymentFailedButton
                    type="Creator"
                    onPress={() => setPaymentFailedVisible(true)}
                    containerStyles={{ marginBottom: 10 }}
                  />
                }
                location="Profile Account"
              />
            ) : (
              <div style={{ paddingBottom: 7 }}>
                <span
                  style={{
                    ...styles.bodyMediumBold,
                    color: frozenSubscription ? Colors.RED3 : Colors.BLACK,
                  }}
                >
                  {frozenSubscription
                    ? "Frozen"
                    : trialSubscription
                    ? "Trial"
                    : subscriptionPlan.name}
                </span>
              </div>
            )}
            {!trialSubscription && mostRecentSub ? (
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <span style={styles.placeholder}>
                  You are currently on the {subscriptionPlan.name} plan of{" "}
                  <span style={{ color: Colors.BLACK, fontWeight: "500" }}>
                    Markit<span style={{ color: Colors.BLUE5 }}>+</span>
                  </span>
                </span>
                {mostRecentSub.price ? (
                  <span style={styles.placeholder}>
                    Original purchase date:{" "}
                    <span style={{ color: Colors.BLACK }}>
                      {GetNumericDate(mostRecentSub.price[0].date)}
                    </span>
                  </span>
                ) : null}
                {frozenSubscription ? (
                  <span style={styles.placeholder}>
                    Freeze Date:{" "}
                    {GetNumericDate(accountData.customer.frozenDate)}
                  </span>
                ) : null}
                {mostRecentSub &&
                downgradeSubscriptionPlan &&
                accountData.customer.subscriptionSchedule !== "" ? (
                  <span style={styles.placeholder}>
                    Downgrading Date to {downgradeSubscriptionPlan.name}:{" "}
                    {GetNumericDate(mostRecentSub.end)}
                  </span>
                ) : null}
              </div>
            ) : (
              <span style={styles.placeholder}>
                You are currently on a free trial of Markit+
              </span>
            )}
            {!cancelSubscriptionAtEnd &&
            !isSubscriptionPaymentFailed(accountData.customer.state) ? (
              <>
                <HorizontalDivider altMargin={14} />
                <div className="ColumnNormal" style={{ gap: "14px" }}>
                  <SubscriptionDetailsOption
                    cancelSubscriptionAtEnd={cancelSubscriptionAtEnd}
                    mostRecentSubEnd={mostRecentSub?.end}
                    label={"Change Payment Method"}
                    onPress={navigateChangePaymentOnPress}
                  />
                  <SubscriptionDetailsOption
                    cancelSubscriptionAtEnd={cancelSubscriptionAtEnd}
                    mostRecentSubEnd={mostRecentSub?.end}
                    label={"Change Subscription Plan"}
                    onPress={navigateUpgradeOnPress}
                  />
                  <SubscriptionDetailsOption
                    cancelSubscriptionAtEnd={cancelSubscriptionAtEnd}
                    mostRecentSubEnd={mostRecentSub?.end}
                    label={
                      frozenSubscription
                        ? "Un-Freeze Subscription"
                        : "Freeze Subscription"
                    }
                    onPress={navigateFreezeOnPress}
                  />
                </div>
              </>
            ) : null}
          </div>
        ) : (
          <div
            className="ColumnNormal"
            style={{ gap: 7, paddingInline: 14, width: "80%" }}
          >
            <span style={styles.bodyMediumBold}>Free</span>
            <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
              You are currently on the Free plan of Markit.{" "}
              <span style={{ color: Colors.BLACK, fontWeight: 500 }}>
                Upgrade to Markit<span style={{ color: Colors.BLUE5 }}>+</span>{" "}
                to get additional features, texting, and support.
              </span>
            </span>
          </div>
        )}
        <hr />
        <div className="ColumnNormal" style={{ gap: 14, paddingInline: 14 }}>
          {isFreeSubscription ? (
            <SubscriptionDetailsOption
              cancelSubscriptionAtEnd={false}
              mostRecentSubEnd={mostRecentSub?.end}
              label={
                <span style={styles.bodyMediumBold}>
                  Get Markit <span style={{ color: Colors.BLUE5 }}>+</span>
                </span>
              }
              onPress={() => navigate("/subscription")}
            />
          ) : (
            <SubscriptionDetailsOption
              cancelSubscriptionAtEnd={cancelSubscriptionAtEnd}
              mostRecentSubEnd={mostRecentSub?.end}
              label={
                !trialSubscription ? "Cancel Subscription" : "Cancel Trial"
              }
              onPress={navigateCancelOnPress}
            />
          )}
        </div>
      </StandardBorderedContainer>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
});

export default SubscriptionDetails;
