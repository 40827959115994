import { useCallback, useMemo, useState } from "react";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";
import HelpModal from "../../../components/Containers/HelpModal";
import SubscriptionDetails from "../../../components/Subscription/SubscriptionDetails";
import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../../components/Containers/StandardBorderedContainer";
import {
  MARKITAI,
  MARKIT_HOTLINE_NUMBER,
  hasSubscription,
  isBusinessSubscription,
  isSubscriptionPaymentFailed,
  mostRecentSubscription,
  mostRecentSubscriptionPlan,
  subscriptionOverflowRate,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { RWebShare } from "react-web-share";
import { onShareLink } from "../../../utils/shareLink";
import { Icon } from "@iconify/react";
import SubscriptionPlanCard from "../../../components/Subscription/SubscriptionPlanCard";
import RectangleButton from "../../../components/Buttons/RectangleButton";
import { formatPhoneNumber } from "../../../utils/FormatPhoneNumber";
import { Subscription } from "@markit/common.types";
import { FormatCurrency } from "../../../utils/FormatCurrency";
import TextingPanelUsage from "../../../components/CreatorDashboard/TextingPanelUsage";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import { TabPanel } from "../../../components/FullEventSubComponents/TabPanel";
import SubscriptionBillingItem from "../../../components/Subscription/Items/SubscriptionBillingItem";
import { HorizontalDivider } from "../../../components/Dividers/HorizontalDivider";
import EmptyStateButton from "../../../components/Buttons/EmptyStateButton";

const BillingPanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const [value, setValue] = useState(0);
  const [helpModal, setHelpModal] = useState(false);

  const styles = {
    bodyMedium: {
      fontSize: 14,
      color: Colors.GRAY1,
    },
    bodyMediumBold: {
      fontSize: 14,
      fontWeight: 500,
      color: Colors.BLACK,
    },

    headerText: {
      fontSize: 32,
    },
  };

  const subscriptionPlan = useMemo(
    () => mostRecentSubscriptionPlan(accountData),
    [accountData]
  );

  const trialSubscription = useMemo(
    () => accountData.customer.state === "TRIAL",
    [accountData.customer.state]
  );

  // Creates a mock upcoming subscription object if there is an active subscription.
  // It creates a new subscription object with the start date set to the end date of the most recent subscription and the end date set to one month later.
  // If the subscription payment has failed, it returns undefined.
  const mockUpcomingSub = useMemo(() => {
    if (
      hasSubscription(accountData) &&
      !isSubscriptionPaymentFailed(accountData.customer.state)
    ) {
      const sub = mostRecentSubscription(accountData);
      if (!sub) {
        return undefined;
      }
      const endDate = new Date(sub.end);
      endDate.setMonth(endDate.getMonth() + 1);

      return {
        ...sub,
        start: sub.end,
        end: endDate.toISOString(),
        message: 0,
      };
    }
  }, [accountData]);

  const subscriptionInvoices = useMemo(
    () =>
      mockUpcomingSub
        ? [...accountData.customer.subscription, mockUpcomingSub].reverse()
        : [...accountData.customer.subscription].reverse(),
    [accountData, mockUpcomingSub]
  );

  const overflowPriceCents = useMemo(
    () => subscriptionOverflowRate(accountData),
    [accountData]
  );

  const amountDue = useCallback(
    (item: Subscription, index: number) =>
      (item.price[0].amount +
        Math.max(
          0,
          overflowPriceCents *
            (index < subscriptionInvoices.length - 1
              ? subscriptionInvoices[index + 1].message -
                subscriptionInvoices[index + 1].price[0].limit
              : 0)
        )) /
      100,
    [overflowPriceCents, subscriptionInvoices]
  );

  const shareMarkit = useCallback(async () => {
    await onShareLink("Share Markit with your friends!", MARKITAI);
  }, []);

  return (
    <>
      <CreatorModePanelWrapper
        title={
          <div className="ColumnNormal" style={{ gap: 20 }}>
            <span
              className="TextOverflowEllipsis"
              style={{ ...styles.headerText, maxWidth: 600 }}
            >
              {"Billing"}
            </span>
            <StandardBorderedContainer
              containerStyles={{ padding: 14, borderRadius: 12 }}
            >
              <div className="AlignedRow">
                <span style={styles.bodyMedium}>
                  You are currently {trialSubscription ? "trialing" : "on"} the{" "}
                  <span style={styles.bodyMediumBold}>
                    {subscriptionPlan.name}
                  </span>{" "}
                  Monthly plan for{" "}
                  <span style={styles.bodyMediumBold}>
                    {trialSubscription
                      ? FormatCurrency(0)
                      : FormatCurrency(subscriptionPlan.price / 100)}{" "}
                    per month
                  </span>
                  .{" "}
                  {isBusinessSubscription(accountData)
                    ? ""
                    : "Upgrade at any time."}
                </span>
              </div>
            </StandardBorderedContainer>
          </div>
        }
        headerRight={
          <RectangleButtonCreatorPanel
            title="Contact Sales"
            onPress={() => setHelpModal(true)}
            iconName="ic:round-arrow-outward"
            addHover
          />
        }
        headerTabs={{
          tabNames: ["Overview", "History"],
          tabValue: value,
          setTabValue: setValue,
        }}
      >
        <div
          style={{
            padding: 30,
            height: value === 0 ? "auto" : "100%",
            backgroundColor: value === 0 ? Colors.WHITE1 : Colors.WHITE,
            paddingBottom: 50,
          }}
        >
          <TabPanel value={value} index={0}>
            <div className="RowNormal" style={{ gap: 24 }}>
              <div className="ColumnNormal" style={{ gap: 24, flex: 1 }}>
                <SubscriptionDetails />
                <StandardBorderedContainer
                  containerStyles={{
                    backgroundColor: Colors.WHITE,
                    borderRadius: 20,
                    padding: 14,
                  }}
                >
                  <RWebShare data={{ url: MARKITAI }}>
                    <div
                      className="AlignedRowSpacedSelect"
                      onClick={shareMarkit}
                    >
                      <span style={styles.bodyMediumBold}>Share Markit</span>
                      <Icon
                        icon="mdi:chevron-right"
                        height={24}
                        color={Colors.GRAY1}
                      />
                    </div>
                  </RWebShare>
                </StandardBorderedContainer>
              </div>
              <div className="ColumnNormal" style={{ flex: 1, gap: 24 }}>
                <TextingPanelUsage onBilling />
                <SubscriptionPlanCard
                  cardButton={
                    <RectangleButton
                      buttonLabel="Current Plan"
                      onPress={() => {}}
                      altColor={Colors.GRAY6}
                      disabled
                      altPaddingHorz={14}
                      altPaddingVert={14}
                      containerStyles={{ marginTop: 10 }}
                    />
                  }
                  isSelected={false}
                  subscriptionPlan={subscriptionPlan}
                />
                <span style={{ ...styles.bodyMedium, textAlign: "center" }}>
                  For enterprise or other questions contact sales at{" "}
                  <span style={{ color: Colors.BLACK }}>
                    {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
                  </span>
                </span>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {subscriptionInvoices.length && !trialSubscription ? (
              <div className="ColumnNormal">
                {subscriptionInvoices.map((item, index) => (
                  <div key={index}>
                    <SubscriptionBillingItem
                      subscription={item}
                      lastMonthSubscription={
                        index < subscriptionInvoices.length - 1
                          ? subscriptionInvoices[index + 1]
                          : undefined
                      }
                      showBaseline={true}
                      showOverflow={
                        index < subscriptionInvoices.length - 1 &&
                        subscriptionInvoices[index + 1].message >
                          subscriptionInvoices[index + 1].price[0].limit
                      }
                      amount={amountDue(item, index)}
                      showIcon={true}
                      isUpcoming={index === 0}
                      displayInvoice={true}
                    />
                    <HorizontalDivider altMargin={14} />
                  </div>
                ))}
              </div>
            ) : (
              <EmptyStateButton
                title="No Billing History"
                description="Your subscription and other charges show up here"
                icon={
                  <Icon icon="ion:receipt" height={64} color={Colors.GRAY1} />
                }
                iconBox={84}
                containerStyles={{ paddingTop: 150 }}
              />
            )}
          </TabPanel>
        </div>
      </CreatorModePanelWrapper>
      <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
    </>
  );
};

export default BillingPanel;
