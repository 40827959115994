import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getAccountState,
  logoutUser,
  toggleInCreatorMode,
} from "../../../redux/slices/accountSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "../../../hooks/useNavigate";
import ProfilePic from "../../ProfilePic";
import { NavigationId } from "../../../navigation/AppParamList";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { DataLoaders } from "../../../redux/slices/dataSlice";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import { NotificationType, ThemeType } from "@markit/common.types";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";
import { Icon } from "@iconify/react";
import { DarkTheme, useTheme } from "../../../hooks/useTheme";
import { Colors } from "../../../utils/colors";
import { isDesktop } from "react-device-detect";
import { OnboardingStage } from "../../../screens/Login/LoginOnboarding";

type ProfileHeaderDropdownProps = {
  children: ReactNode;
  placement: "bottom" | "left" | "right";
  eventTheme?: ThemeType;
};

const ProfileHeaderDropdown = (props: ProfileHeaderDropdownProps) => {
  const { children, placement, eventTheme } = props;
  const navigate = useNavigate();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const { theme } = useTheme(eventTheme);
  const ref = useRef<any>(null);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const isDarkTheme = useMemo(() => theme && theme === DarkTheme, [theme]);

  const styles = {
    leftPlacement: {
      left: "100%",
      top: 14,
    },
    bottomPlacement: {
      right: isDesktop ? 24 : 14,
    },
    rightPlacement: {},
  };

  useEffect(() => {
    const handler = (event: any) => {
      if (
        dropdownVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdownVisible]);

  const viewProfile = useCallback(() => {
    if (accountData.inCreatorMode) {
      navigate(NavigationId.HOME_PROFILE);
    } else {
      dispatch(DataLoaders.user(accountData.uid));
      navigate(`/u/${accountData.username}`, {
        state: { username: accountData.username },
      });
    }
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    accountData.username,
    dispatch,
    navigate,
  ]);

  const switchMode = useCallback(() => {
    dispatch(toggleInCreatorMode(accountData.uid, accountData.inCreatorMode));
    showNotificationBanner(dispatch, "Switched", NotificationType.AFFIRMATIVE);
    if (accountData.haveNotFinishedInitialStartup) {
      // if account has not set own username (finished sign up flow)
      navigate(NavigationId.ONBOARDING, {
        state: { defaultStage: OnboardingStage.PROFILE_SETUP },
      });
    } else if (!accountData.inCreatorMode) {
      navigate("/home");
    } else {
      dispatch(DataLoaders.user(accountData.uid));
      navigate(`/u/${accountData.username}`, {
        state: { username: accountData.username },
      });
    }
  }, [
    accountData.haveNotFinishedInitialStartup,
    accountData.inCreatorMode,
    accountData.uid,
    accountData.username,
    dispatch,
    navigate,
  ]);

  const signOut = useCallback(() => {
    mixpanel.track("Webapp: User Logout", {
      distinct_id: accountData.uid,
    });
    dispatch(logoutUser());
    navigate("/");
  }, [accountData.uid, dispatch, mixpanel, navigate]);

  const renderItem = useCallback(
    (label: string, iconName: string, onPress: () => void) => (
      <m.div
        className="AlignedRowSpacedSelect"
        style={{
          paddingInline: 10,
          paddingBlock: 12,
          borderRadius: 8,
          marginInline: 4,
          backgroundColor: isDarkTheme ? Colors.GRAY10 : Colors.WHITE,
        }}
        whileHover={{
          backgroundColor: isDarkTheme ? Colors.GRAY7 : Colors.GRAY6,
        }}
        transition={{ duration: 0.2 }}
        onClick={onPress}
      >
        <span style={{ fontSize: 13, fontWeight: 500, ...theme.PrimaryText }}>
          {label}
        </span>
        <Icon icon={iconName} height={18} style={{ ...theme.PrimaryText }} />
      </m.div>
    ),
    [isDarkTheme, theme.PrimaryText]
  );

  return (
    <div>
      <div
        style={{ pointerEvents: dropdownVisible ? "none" : "all" }}
        onClick={() => setDropdownVisible(true)}
      >
        {children}
      </div>
      <div ref={ref}>
        <AnimatePresence>
          {dropdownVisible ? (
            <LazyMotion features={domAnimation}>
              <m.div
                key="profilePicDropdown"
                className="HeaderProfilePicDropdown"
                style={{
                  paddingBlock: 4,
                  backgroundColor: isDarkTheme ? Colors.GRAY10 : Colors.WHITE,
                  border: `1.5px solid ${theme.DividerColor.borderColor}`,
                  ...(placement === "left"
                    ? styles.leftPlacement
                    : placement === "bottom"
                    ? styles.bottomPlacement
                    : styles.rightPlacement),
                }}
                initial={{
                  x: placement === "left" ? "-10px" : undefined,
                  y: placement === "bottom" ? "-10px" : undefined,
                  opacity: 0,
                  filter: "blur(2px)",
                }}
                animate={{
                  x: placement === "left" ? "14px" : undefined,
                  y: placement === "bottom" ? "14px" : undefined,
                  opacity: 1,
                  filter: "blur(0px)",
                }}
                exit={{
                  x: placement === "left" ? "-10px" : undefined,
                  y: placement === "bottom" ? "-10px" : undefined,
                  opacity: 0,
                  filter: "blur(2px)",
                }}
                transition={{ duration: 0.2, ease: "easeOut" }}
              >
                <div className="AlignedRow" style={{ gap: 7, padding: 10 }}>
                  <div>
                    <ProfilePic user={accountData} size={40} />
                  </div>
                  <div className="ColumnNormal" style={{ gap: 5 }}>
                    <span className="bodyMedium" style={theme.PrimaryText}>
                      {accountData.fullName}
                    </span>
                    <span className="smallBodySubtext">
                      @{accountData.username}
                    </span>
                  </div>
                </div>
                <HorizontalDivider altMargin={4} />
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  {renderItem("View Profile", "ion:person-circle", viewProfile)}
                  {renderItem(
                    `Switch to ${
                      accountData.inCreatorMode ? "Subscriber" : "Creator"
                    }`,
                    "mdi:swap-horizontal-variant",
                    switchMode
                  )}
                  {renderItem("Sign Out", "ion:exit", signOut)}
                </div>
              </m.div>
            </LazyMotion>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ProfileHeaderDropdown;
