import "../../css/Subscription.css";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { API } from "../../API";
import {
  stripePromise,
  testStripe,
} from "../../components/FullEvent/FullEventInputForm";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useSelector, useDispatch } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import TopHeader from "../../components/TopHeader";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import { useTheme } from "../../hooks/useTheme";
import StandardBorderedContainer from "../../components/Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../../components/Dividers/HorizontalDivider";
import RectangleButton from "../../components/Buttons/RectangleButton";
import {
  convertToMoney,
  hasSubscription,
  isSubscriptionPaymentFailed,
  MARKIT_HOTLINE_NUMBER,
  mostRecentSubscription,
  subscriptionOverflowRate,
  subscriptionPlanType,
} from "@markit/common.utils";
import { CircularProgress } from "@mui/material";
import { getUserRef } from "../../utils/FirebaseUtils";
import { onSnapshot } from "../../firebase";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import SubscriptionBillingItem from "../../components/Subscription/Items/SubscriptionBillingItem";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionCheckoutForm, {
  SubscriptionCheckoutType,
} from "../../components/Subscription/SubscriptionCheckoutForm";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import AlertContainer from "../../components/Containers/AlertContainer";
import AgreeToTerms from "../../components/AgreeToTerms";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { useNavigate } from "react-router-dom";
import { NotificationType } from "@markit/common.types";
import { showNotificationBanner } from "../../utils/notificationUtils";

const SubscriptionCancel = memo(function SubscriptionCancelFn() {
  const { theme } = useTheme();
  const { accountData, appInitialized } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const [processing, setProcessing] = useState<boolean>(false);
  const [confirmationView, setConfirmationView] = useState(false);
  const [subscriptionConfirm, setSubscriptionConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showingPaymentSheet, setShowingPaymentSheet] = useState(false);
  const [options, setOptions] = useState<{
    clientSecret: string;
    appearance: {};
  }>();
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [agreeToTermsError, setAgreeToTermsError] = useState(false);
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (appInitialized && loading) {
      if (!hasSubscription(accountData)) {
        alert("You do not have a subscription!");
        navigate("/home");
      }
      setLoading(false);
    }
  }, [accountData, appInitialized, loading, navigate]);

  // For active overflow case, get the most recent subcription
  // For payment failed case, get the most recent sentInvoice subscription
  const mostRecentActiveSub = useMemo(() => {
    if (isSubscriptionPaymentFailed(accountData.customer.state)) {
      const sentInvoiceSubs = accountData.customer.subscription.filter((sub) =>
        sub.price.some((p) => p.sentInvoice)
      );
      return sentInvoiceSubs[sentInvoiceSubs.length - 1];
    } else {
      return mostRecentSubscription(accountData);
    }
  }, [accountData]);

  const subscriptionPlan = useMemo(
    () =>
      mostRecentActiveSub
        ? subscriptionPlanType(mostRecentActiveSub.price[0].id, accountData.uid)
        : undefined,
    [accountData.uid, mostRecentActiveSub]
  );

  const trialSubscription = useMemo(
    () => accountData.customer.state === "TRIAL",
    [accountData.customer.state]
  );

  const overflowPrice = useMemo(
    () => subscriptionOverflowRate(accountData),
    [accountData]
  );

  // The balance from overflow that needs to be paid to cancel (in cents)
  const outstandingBalance = useMemo(
    () =>
      subscriptionPlan && mostRecentActiveSub
        ? Math.round(
            Math.max(
              mostRecentActiveSub.message - subscriptionPlan.baselineLimit,
              0
            ) * overflowPrice
          )
        : 0,
    [subscriptionPlan, mostRecentActiveSub, overflowPrice]
  );

  // Not valid if outstanding balance is less than 50 cents, can't create paymentIntent less than 0.5
  const validOutstandingBalance = useMemo(
    () => outstandingBalance > 50,
    [outstandingBalance]
  );

  const styles = {
    middleContainer: {
      backgroundColor: Colors.WHITE1,
      flex: 1,
      flexGrow: 1,
      paddingTop: 30,
      paddingBottom: 100,
      gap: 24,
    },
  };

  const fetchPaymentSheetParams = useCallback(async () => {
    setOptions(undefined);

    (testStripe
      ? API.user.initializePayCancelTestSubscription
      : API.user.initializePayCancelSubscription)({
      uid: accountData.uid,
      amount: outstandingBalance,
    })
      .then(async (res: any) => {
        const { paymentIntent } = res;
        const stripeOptions = {
          // passing the client secret obtained in step 2
          clientSecret: paymentIntent,
          appearance: {
            theme: "stripe",
            variables: { colorBackground: Colors.WHITE },
          },
        };
        setOptions(stripeOptions);
      })
      .catch((err: any) => {
        console.error("Error on initialize: " + err.message);
      });
  }, [accountData.uid, outstandingBalance]);

  const continueToPayment = useCallback(async () => {
    if (!agreedToTerms) {
      setAgreeToTermsError(true);
      return;
    }
    setShowingPaymentSheet(true);
    fetchPaymentSheetParams();
  }, [agreedToTerms, fetchPaymentSheetParams]);

  const cancelSubscription = useCallback(async () => {
    setProcessing(true);
    if (mixpanel) {
      mixpanel.identify(accountData.uid);
      mixpanel.track("User Cancelling Markit+ Subscription", {
        date: new Date(),
        distinct_id: accountData.uid,
      });
    }
    await (testStripe
      ? API.user.cancelTestSubscription
      : API.user.cancelSubscription)(accountData.uid, {
      uid: accountData.uid,
    })
      .then((res: any) => {
        // Listen for subscription changed to inactive
        const userRef = getUserRef(accountData.uid);
        const unsubscribe = onSnapshot(userRef, (snapshot) => {
          const userData = snapshot.data();
          if (userData && userData.customer.state === "INACTIVE") {
            setConfirmationView(true);
            setProcessing(false);
            showNotificationBanner(
              dispatch,
              "Subscription Cancelled",
              NotificationType.NEGATIVE
            );
            unsubscribe();
          }
        });
      })
      .catch((err: any) => {
        setProcessing(false);
        alert("An Error Occurred when cancelling your subscription.");
        console.error(err.message);
      });
  }, [accountData.uid, dispatch, mixpanel]);

  const paymentOrRsvpElement = useMemo(() => {
    if (options !== undefined) {
      return (
        <div className="SubscriptionCheckoutPaymentSheet">
          <Elements
            key={options.clientSecret}
            stripe={stripePromise}
            options={options}
          >
            <SubscriptionCheckoutForm
              label={"Outstanding Balance"}
              amount={outstandingBalance}
              processing={processing}
              setProcessing={setProcessing}
              isReadyForPayment={true}
              clientSecret={options.clientSecret}
              checkoutType={SubscriptionCheckoutType.CANCEL}
              onConfirmApplePay={cancelSubscription}
              onPress={async (stripe: Stripe, elements: StripeElements) => {
                setProcessing(true);
                const { error } = await stripe.confirmPayment({
                  elements,
                  redirect: "if_required",
                });

                if (error) {
                  console.error(error.message);
                  setAlertText({
                    heading:
                      error.message ??
                      "An error occurred when entering your info.",
                    subHeading: "Please try again.",
                  });
                  setProcessing(false);
                } else {
                  if (agreedToTerms) {
                    mixpanel.track("Webapp: Agreed to Terms", {
                      distinct_id: accountData.uid,
                      type: "Cancel Subscription",
                      subsription_plan: subscriptionPlan?.name,
                    });
                  }
                  await cancelSubscription();
                }
              }}
            />
          </Elements>
        </div>
      );
    } else {
      return (
        <div className="ColumnNormal" style={{ gap: 14 }}>
          <div className="AlignedRowSpaced">
            <span style={{ fontWeight: 500 }}>Total</span>
            <span style={{ fontSize: 24, fontWeight: 500 }}>
              {convertToMoney(outstandingBalance / 100)}
            </span>
          </div>
          <AgreeToTerms
            agreed={agreedToTerms}
            setAgreed={setAgreedToTerms}
            error={agreeToTermsError}
            setError={setAgreeToTermsError}
            disabled={showingPaymentSheet}
            nonRefundable
          />
          <RectangleButton
            buttonLabel="Continue to Payment"
            onPress={continueToPayment}
            altPaddingVert={14}
            altPaddingHorz={14}
            altColor={Colors.BLACK}
            altTextColor={Colors.WHITE}
            loading={showingPaymentSheet}
          />
        </div>
      );
    }
  }, [
    accountData.uid,
    agreeToTermsError,
    agreedToTerms,
    cancelSubscription,
    continueToPayment,
    mixpanel,
    options,
    outstandingBalance,
    processing,
    showingPaymentSheet,
    subscriptionPlan?.name,
  ]);

  return (
    <div className="ColumnNormal" style={{ height: "100vh" }}>
      <TopHeader />
      <div className="ColumnNormal" style={{ paddingTop: 30, flex: 1 }}>
        <div className="AlignedColumn">
          <span style={{ fontSize: 32, fontWeight: "500" }}>
            {confirmationView
              ? "Subscription Cancelled"
              : trialSubscription
              ? "Cancel Your Trial"
              : "Cancel Your Subscription"}
          </span>
          <div
            style={{
              width: isDesktop ? 610 : "90%",
              textAlign: "center",
              paddingBlock: 30,
            }}
          >
            {confirmationView ? (
              <span className="bodyMedium" style={theme.LabelText}>
                You have successfully cancelled your Markit+{" "}
                {trialSubscription ? "Trial" : "subscription"}.
              </span>
            ) : (
              <span className="bodyMedium" style={theme.LabelText}>
                Upon cancelling your Markit+{" "}
                {trialSubscription ? "Trial" : "subscription"}, your creator
                phone number will be permanently released and all of your
                conversations associated with that number deleted.
                <br />
                <br />
                {trialSubscription
                  ? "You will"
                  : "You will not be refunded for any remaining texting credits and will also"}{" "}
                lose access to all Markit+ benefits immediately upon
                cancellation to avoid incurring additional charges.
                {!trialSubscription ? (
                  <>
                    <br />
                    <br />
                    <span>
                      You may contact our{" "}
                      <a
                        href={`sms:${MARKIT_HOTLINE_NUMBER}`}
                        style={{
                          fontWeight: 500,
                          color: Colors.BLUE5,
                          textDecorationLine: "underline",
                        }}
                      >
                        hotline
                      </a>{" "}
                      if you would prefer to freeze your subscription for a
                      small fee.
                    </span>
                  </>
                ) : null}
              </span>
            )}
          </div>
        </div>
        {!loading && subscriptionPlan && mostRecentActiveSub ? (
          <div className="AlignedColumn" style={styles.middleContainer}>
            <StandardBorderedContainer
              containerStyles={{
                paddingBottom: 14,
                paddingTop: 20,
                borderRadius: 14,
                backgroundColor: Colors.WHITE,
                width: isDesktop ? 390 : "90%",
              }}
            >
              <div className="ColumnNormal" style={{ gap: 14 }}>
                {validOutstandingBalance && !confirmationView ? (
                  <div className="ColumnNormal" style={{ gap: 24 }}>
                    <span className="bodyMedium" style={{ paddingInline: 14 }}>
                      To cancel your subscription you must pay your outstanding
                      balance.
                    </span>
                    <HorizontalDivider />
                  </div>
                ) : null}
                <SubscriptionBillingItem
                  subscription={mostRecentActiveSub}
                  showBaseline={!validOutstandingBalance}
                  showOverflow={validOutstandingBalance}
                  amount={
                    trialSubscription
                      ? 0
                      : validOutstandingBalance
                      ? outstandingBalance / 100
                      : subscriptionPlan.price / 100
                  }
                  showIcon={validOutstandingBalance}
                  isUpcoming
                />
                <HorizontalDivider />
              </div>
              <div
                className="ColumnNormal"
                style={{
                  gap: 4,
                  paddingTop: options ? 10 : 24,
                  paddingInline: 14,
                }}
              >
                {validOutstandingBalance && !confirmationView ? (
                  paymentOrRsvpElement
                ) : (
                  <>
                    <RectangleButton
                      buttonLabel={
                        confirmationView
                          ? "Subscription Cancelled"
                          : trialSubscription
                          ? "Cancel Trial"
                          : "Cancel Subscription"
                      }
                      onPress={() => setSubscriptionConfirm(true)}
                      altColor={Colors.RED3}
                      altTextColor={Colors.WHITE}
                      altPaddingHorz={14}
                      altPaddingVert={14}
                      loading={processing}
                      disabled={confirmationView}
                    />
                    {processing ? (
                      <span
                        className="smallBodySubtext"
                        style={{ textAlign: "center" }}
                      >
                        Loading... Please do not leave this page!
                      </span>
                    ) : null}
                  </>
                )}
              </div>
            </StandardBorderedContainer>
            <span className="bodySubtext">
              For questions, contact our hotline at{" "}
              <a
                href={`sms:${MARKIT_HOTLINE_NUMBER}`}
                style={{ ...theme.PrimaryText, fontWeight: 500 }}
              >
                {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
              </a>
            </span>
          </div>
        ) : (
          <div
            className="Centering"
            style={{ flex: 1, backgroundColor: Colors.WHITE1 }}
          >
            <CircularProgress size={24} style={{ color: Colors.BLACK }} />
          </div>
        )}
      </div>
      <ConfirmDeleteModal
        heading={`Are you sure you want to cancel your ${
          trialSubscription ? "trial" : "subscription"
        }?`}
        subtext={"You cannot undo this."}
        deleteButtonText={`End ${trialSubscription ? "Trial" : "Subscription"}`}
        hideModal={!subscriptionConfirm}
        setIsVisible={setSubscriptionConfirm}
        deleteOnPress={cancelSubscription}
        theme={theme}
      />
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => {
          setAlertText({ heading: "", subHeading: "" });
        }}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </div>
  );
});

export default SubscriptionCancel;
