import { memo, useCallback, useMemo, useState } from "react";
import {
  calculateTextMessageCreditsEstimate,
  complianceMessage,
  handlePluralString,
  hasSubscription,
  MARKIT_TEXTING_CREDITS,
  mostRecentSubscription,
  SUBSCRIBED_CAMPAIGN_NAME,
  userPhoneVerificationStatus,
} from "@markit/common.utils";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import RectangleButton from "../../../Buttons/RectangleButton";
import { Colors } from "../../../../utils/colors";
import TextCreditsNotice from "./TextCreditsNotice";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { fetchNumFollowersNoComplianceSent } from "../../../../utils/userUtils";
import {
  Event,
  MassText,
  TwilioPhoneVerificationStatus,
} from "@markit/common.types";
import { getCampaignState } from "../../../../redux/slices/campaignSlice";
import TextHoverButton from "../../../Buttons/TextHoverButton";
import { NavigationId } from "../../../../navigation/AppParamList";
import { useNavigate } from "react-router-dom";
import ConfirmActionModal from "../../../Containers/ConfirmPopups/ConfirmActionModal";

type ConfirmSendTextModalProps = {
  setModalVisible: (modalVisible: boolean) => void;
  confirmOnPress: () => void;
  isTriggerText: boolean;
  isScheduled: boolean;
  numCredits: number;
  foundEventText: Event | undefined;
  massText: MassText;
  recipients: string[];
  isSending: boolean;
  showCategories: boolean;
};

export const ConfirmSendTextModal = memo(function ConfirmSendTextModalFn(
  props: ConfirmSendTextModalProps
) {
  const {
    setModalVisible,
    confirmOnPress,
    isTriggerText,
    isScheduled,
    numCredits,
    massText,
    foundEventText,
    recipients,
    isSending,
    showCategories,
  } = props;
  const { followersData, accountData } = useSelector(getAccountState).account;
  const { campaigns } = useSelector(getCampaignState);
  const navigate = useNavigate();
  const [confirmViewStatus, setConfirmViewStatus] = useState(false);

  const accountStatus = useMemo(
    () => userPhoneVerificationStatus(accountData),
    [accountData]
  );

  const showCategoryView = useMemo(
    () => showCategories && isScheduled,
    [isScheduled, showCategories]
  );

  const isClickedSubscribeText = useMemo(() => {
    const foundCampaign = campaigns.find(
      (campaign) => campaign.initialTextId === massText.id
    );
    return foundCampaign
      ? foundCampaign.title === SUBSCRIBED_CAMPAIGN_NAME
      : false;
  }, [campaigns, massText.id]);

  const numNonCompliantRecipients = useMemo(
    () =>
      fetchNumFollowersNoComplianceSent(accountData, followersData, recipients),
    [accountData, followersData, recipients]
  );

  const numCompliantRecipients = useMemo(
    () => recipients.length - numNonCompliantRecipients,
    [recipients.length, numNonCompliantRecipients]
  );

  const numComplianceCredits = useMemo(
    () =>
      calculateTextMessageCreditsEstimate(
        massText.message +
          complianceMessage(accountData.fullName, hasSubscription(accountData)),
        massText.mediaUrl,
        foundEventText
      ),
    [accountData, foundEventText, massText.mediaUrl, massText.message]
  );

  // The total number of credits factors in the compliance credits if the user is sending to non-compliance recipients or it's the clicked subscribe text
  const totalNumCredits = useMemo(
    () =>
      (numNonCompliantRecipients > 0 && numCredits !== numComplianceCredits) ||
      isClickedSubscribeText
        ? numCredits * numCompliantRecipients +
          numComplianceCredits *
            (isClickedSubscribeText ? 1 : numNonCompliantRecipients)
        : numCredits * recipients.length,
    [
      isClickedSubscribeText,
      numCredits,
      numCompliantRecipients,
      numComplianceCredits,
      numNonCompliantRecipients,
      recipients.length,
    ]
  );

  // Check if sending total num credits for trial subscription will exceed limit
  const exceedsTrialMessageLimit = useMemo(() => {
    const mostRecentSub = mostRecentSubscription(accountData);
    if (mostRecentSub) {
      const mostRecentLimit = mostRecentSub.price.find(
        (p) => p.type === "baseline"
      )?.limit;
      if (
        accountData.customer.state === "TRIAL" &&
        mostRecentLimit &&
        mostRecentSub.message + totalNumCredits > mostRecentLimit
      ) {
        return true;
      }
    }
    return false;
  }, [accountData, totalNumCredits]);

  const textingCreditsOnPress = useCallback(() => {
    window.open(MARKIT_TEXTING_CREDITS);
  }, []);

  return (
    <>
      <PopupModalContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 14, fontWeight: 400 }}>
            <Icon
              icon={
                !isTriggerText && isScheduled ? "ion:timer" : "ion:paper-plane"
              }
              height={35}
            />
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span className="sectionTitle">
                {exceedsTrialMessageLimit
                  ? "This text will exceed your free trial credits"
                  : isTriggerText
                  ? "Save Trigger?"
                  : isScheduled
                  ? "Ready to Schedule?"
                  : "Ready to Send?"}
              </span>
              {exceedsTrialMessageLimit ? (
                <span className="smallBodySubtext">
                  You will immediately be charged for the price of your
                  subscription plan with the card on file.
                </span>
              ) : recipients.length > 0 && !showCategoryView ? (
                <span className="smallBodySubtext">
                  This text will send to {recipients.length}{" "}
                  {handlePluralString("recipient", recipients.length)}
                  {massText.mediaUrl ? " with media pricing applied" : ""}. This
                  cannot be undone.
                </span>
              ) : null}
            </div>
            <TextCreditsNotice numCredits={numCredits} />
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <div className="AlignedRow" style={{ gap: 7 }}>
                <Icon icon={"ion:prism"} height={15} />
                <span className="bodyMedium">
                  {showCategoryView || isTriggerText ? (
                    <span>
                      This text will use{" "}
                      <span style={{ color: Colors.BLUE5 }}>
                        {isClickedSubscribeText ? totalNumCredits : numCredits}{" "}
                        {handlePluralString(
                          "credit",
                          isClickedSubscribeText ? totalNumCredits : numCredits
                        )}
                      </span>{" "}
                      per recipient
                    </span>
                  ) : (
                    <span>
                      Sending this text will use{" "}
                      <span style={{ color: Colors.BLUE5 }}>
                        {totalNumCredits}{" "}
                        {handlePluralString("credit", totalNumCredits)}
                      </span>
                    </span>
                  )}
                </span>
              </div>
              {isTriggerText ? (
                <span className="smallBodySubtext">
                  The final credit usage of this text will depend on the total
                  number of recipients.
                </span>
              ) : showCategoryView ? (
                <span className="smallBodySubtext">
                  The total credit number may fluctuate based on the total
                  number of recipients at time of send.
                </span>
              ) : (
                <>
                  {numCompliantRecipients ? (
                    <span className="smallBodySubtext">
                      {numCredits} {handlePluralString("credit", numCredits)}{" "}
                      per text for {numCompliantRecipients}{" "}
                      {handlePluralString("recipient", numCompliantRecipients)}
                    </span>
                  ) : null}
                  {numNonCompliantRecipients > 0 ? (
                    <span className="smallBodySubtext">
                      {numComplianceCredits}{" "}
                      {handlePluralString("credit", numCredits)} per text for{" "}
                      {numNonCompliantRecipients}{" "}
                      {handlePluralString(
                        "recipient",
                        numNonCompliantRecipients
                      )}{" "}
                      that require compliance message
                    </span>
                  ) : null}
                </>
              )}
              <TextHoverButton
                text="Learn more about credit usage"
                onPress={textingCreditsOnPress}
                icon={
                  <Icon
                    icon="ion:chevron-forward"
                    height={12}
                    color={Colors.GRAY1}
                  />
                }
                iconPosition="right"
              />
            </div>
            {hasSubscription(accountData) &&
            accountStatus !== TwilioPhoneVerificationStatus.APPROVED ? (
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <div className="AlignedRow" style={{ gap: 7 }}>
                  <Icon
                    icon="ion:warning"
                    height={15}
                    color={
                      accountStatus === TwilioPhoneVerificationStatus.REJECTED
                        ? Colors.RED3
                        : Colors.ORANGE1
                    }
                  />
                  <span
                    className="bodyMedium"
                    style={{
                      color:
                        accountStatus === TwilioPhoneVerificationStatus.REJECTED
                          ? Colors.RED3
                          : Colors.ORANGE1,
                    }}
                  >
                    Warning Before You Send
                  </span>
                </div>
                <span className="smallBodySubtext">
                  Your account{" "}
                  {accountStatus === TwilioPhoneVerificationStatus.REJECTED
                    ? "was rejected"
                    : "is still under review"}
                  . This text will be sent from a different phone number until{" "}
                  {accountStatus === TwilioPhoneVerificationStatus.REJECTED
                    ? "you contact support to resubmit"
                    : "your personal Markit number is approved"}
                  .
                </span>
                <TextHoverButton
                  text="View account status"
                  onPress={() => setConfirmViewStatus(true)}
                  icon={
                    <Icon
                      icon="ion:chevron-forward"
                      height={12}
                      color={Colors.GRAY1}
                    />
                  }
                  iconPosition="right"
                />
              </div>
            ) : null}
          </div>
        }
        valueComp={
          <div className="AlignedRow" style={{ gap: 10 }}>
            <RectangleButton
              buttonLabel="Cancel"
              onPress={() => setModalVisible(false)}
              altTextColor={Colors.BLACK}
              altColor={Colors.GRAY6}
              altPaddingVert={14}
            />
            <RectangleButton
              buttonLabel={
                isTriggerText ? "Save" : isScheduled ? "Schedule" : "Send Text"
              }
              onPress={confirmOnPress}
              altTextColor={Colors.WHITE}
              altColor={Colors.BLACK}
              altPaddingVert={14}
              loading={isSending}
            />
          </div>
        }
        noExit
        closeOnOutsidePress={!confirmViewStatus}
        closeModal={() => setModalVisible(false)}
      />
      <ConfirmActionModal
        heading={"This will redirect you to view your account status."}
        subtext={"Any progress on this text will be lost. Continue?"}
        confirmButtonText={"Continue"}
        hideModal={!confirmViewStatus}
        setIsVisible={() => setConfirmViewStatus(!confirmViewStatus)}
        confirmOnPress={() => navigate(NavigationId.HOME_ACCOUNT_STATUS)}
      />
    </>
  );
});
