import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../../components/Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import VerificationAccountStatusItem from "../../../components/CreatorDashboard/VerificationAccountStatusItem";
import { useSelector, useDispatch } from "react-redux";
import {
  changePhoneShowedAccountApproval,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import {
  GetDateWithTime,
  getTimezone,
  MARKIT_DOCS,
  userPhoneVerificationStatus,
} from "@markit/common.utils";
import { HorizontalDivider } from "../../../components/Dividers/HorizontalDivider";
import TextHoverButton from "../../../components/Buttons/TextHoverButton";
import RectangleButton from "../../../components/Buttons/RectangleButton";
import { useCallback, useMemo, useState } from "react";
import HelpModal from "../../../components/Containers/HelpModal";
import { TwilioPhoneVerificationStatus } from "@markit/common.types";
import { useNavigate } from "react-router-dom";
import { useOnMount } from "../../../hooks/useOnMount";

const VerificationAccountStatusPanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [helpModal, setHelpModal] = useState(false);

  const styles = {
    bodyContainer: {
      backgroundColor: Colors.WHITE1,
      paddingTop: 40,
      height: "100%",
    },
  };

  const accountStatus = useMemo(
    () => userPhoneVerificationStatus(accountData),
    [accountData]
  );

  useOnMount(() => {
    if (
      accountStatus === TwilioPhoneVerificationStatus.APPROVED &&
      accountData.customer.phoneVerification.showedAccountApproval
    ) {
      navigate("/home");
    }
  });

  const accountStatusItems = useMemo(() => {
    if (accountStatus === TwilioPhoneVerificationStatus.APPROVED) {
      return {
        title: "Your account was approved",
        timestampLabel: "Approved",
        icon: "ion:checkmark-circle",
        iconColor: Colors.GREEN2,
      };
    } else if (accountStatus === TwilioPhoneVerificationStatus.REJECTED) {
      return {
        title: "Your account was rejected",
        timestampLabel: "Rejected",
        icon: "ion:close-circle",
        iconColor: Colors.RED3,
      };
    } else {
      return {
        title: "Your account is under review",
        timestampLabel: "Submitted for review",
        icon: "mdi:dots-circle",
        iconColor: Colors.BLACK,
      };
    }
  }, [accountStatus]);

  const footerButtonOnPress = useCallback(() => {
    if (accountStatus === TwilioPhoneVerificationStatus.APPROVED) {
      dispatch(changePhoneShowedAccountApproval(true, accountData.uid));
      navigate("/home");
    } else {
      setHelpModal(true);
    }
  }, [accountData.uid, accountStatus, dispatch, navigate]);

  const learnMoreButton = useMemo(
    () => (
      <TextHoverButton
        text="Learn more about the review process"
        onPress={() => window.open(MARKIT_DOCS + "/accountreview")}
        icon={
          <Icon icon="ion:chevron-right" height={12} color={Colors.BLACK} />
        }
        iconPosition="right"
        textStyles={{ color: Colors.BLACK }}
      />
    ),
    []
  );

  return (
    <CreatorModePanelWrapper
      title="Account Status"
      headerRight={<VerificationAccountStatusItem />}
    >
      <div style={styles.bodyContainer}>
        <div
          className="ColumnNormal"
          style={{ gap: 30, width: 544, paddingBottom: 100, margin: "auto" }}
        >
          <div className="AlignedColumn" style={{ gap: 10 }}>
            <span className="sectionTitle">Account Status</span>
            <span className="bodySubtext" style={{ textAlign: "center" }}>
              When you sign up for a Markit+ account, we submit your information
              to phone carriers for review in order to get your personal Markit
              Number approved for use.
            </span>
          </div>
          <StandardBorderedContainer
            containerStyles={{
              paddingBlock: 14,
              backgroundColor: Colors.WHITE,
            }}
          >
            <div className="ColumnNormal" style={{ gap: 7, paddingInline: 14 }}>
              <div className="AlignedRow" style={{ gap: 7 }}>
                <span className="sectionTitle">{accountStatusItems.title}</span>
                <Icon
                  icon={accountStatusItems.icon}
                  height={19}
                  color={accountStatusItems.iconColor}
                />
              </div>
              {accountStatus === TwilioPhoneVerificationStatus.APPROVED ? (
                <span className="smallBodySubtext">
                  Your account information was approved and your Markit account
                  is now fully activated. Complete your post-onboarding to get
                  familiar with Markit.
                </span>
              ) : accountStatus === TwilioPhoneVerificationStatus.REJECTED ? (
                <span className="smallBodySubtext">
                  Your account was rejected. This could be a result of invalid
                  or incomplete account information. Contact support to
                  resubmit.
                </span>
              ) : (
                <span className="smallBodySubtext">
                  Your account info is under review with phone carriers.{" "}
                  <span style={{ color: Colors.BLACK }}>
                    This process takes 3-5 days.
                  </span>{" "}
                  Contact support with questions or if there is an issue with
                  your account.
                </span>
              )}
              {accountData.customer.phoneVerification.updatedAt ? (
                <span className="smallBodySubtext">
                  {accountStatusItems.timestampLabel}:{" "}
                  {GetDateWithTime(
                    accountData.customer.phoneVerification.updatedAt
                  )}{" "}
                  {getTimezone()}
                </span>
              ) : null}
              {accountStatus === TwilioPhoneVerificationStatus.REJECTED
                ? learnMoreButton
                : null}
            </div>
            {accountStatus === TwilioPhoneVerificationStatus.IN_REVIEW ||
            accountStatus === TwilioPhoneVerificationStatus.PENDING_REVIEW ? (
              <>
                <HorizontalDivider altMargin={14} />
                <div
                  className="ColumnNormal"
                  style={{ gap: 7, paddingInline: 14 }}
                >
                  <span className="sectionTitle">
                    What you can do while your account is in review
                  </span>
                  <span className="smallBodySubtext">
                    You will be able to send texts, add contacts, and share your
                    profile while your account is in review. However, your texts
                    will be sent from a different phone number until your
                    personal Markit number is approved. You will also not have
                    access to two-way texting.
                  </span>
                  {learnMoreButton}
                </div>
              </>
            ) : null}
          </StandardBorderedContainer>
          <RectangleButton
            buttonLabel={
              <span style={{ fontSize: 12 }}>
                {accountStatus === TwilioPhoneVerificationStatus.APPROVED
                  ? "Got It"
                  : "Contact Support"}
              </span>
            }
            onPress={footerButtonOnPress}
            altColor={Colors.BLACK}
            altTextColor={Colors.WHITE}
            containerStyles={{
              borderRadius: 22,
              paddingInline: 16,
              paddingBlock: 12,
              width: 128,
              margin: "auto",
              whiteSpace: "nowrap",
            }}
          />
        </div>
      </div>
      {helpModal ? (
        <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
      ) : null}
    </CreatorModePanelWrapper>
  );
};

export default VerificationAccountStatusPanel;
