import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { ReactNode, useEffect, useRef, useState } from "react";

type PopupModalMessageTagProps = {
  message: string;
  alternateMessageComp?: ReactNode;
  popupTitle: string;
  popupSubtext: string | ReactNode;
  popupPosition?: "top" | "bottom";
  onHover?: boolean;
};

// A message and information icon tag that shows an overlay modal when you press or hover on the icon
const PopupModalMessageTag = (props: PopupModalMessageTagProps) => {
  const {
    message,
    alternateMessageComp,
    popupSubtext,
    popupTitle,
    popupPosition = "bottom",
    onHover,
  } = props;
  const [showDialog, setShowDialog] = useState(false);

  const containerRef = useRef<any>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (
        showDialog &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDialog(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showDialog]);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <div
        className={onHover ? "AlignedRowSelect" : "AlignedRow"}
        style={{ color: Colors.GRAY1, gap: 4 }}
        onMouseEnter={onHover ? () => setShowDialog(true) : undefined}
      >
        {alternateMessageComp || (
          <>
            <span style={{ fontSize: 14 }}>{message}</span>
            <Icon
              icon="mdi:information-outline"
              onClick={!onHover ? () => setShowDialog(!showDialog) : undefined}
              height={14}
              style={{ cursor: "pointer", color: Colors.BLACK, paddingTop: 2 }}
            />
          </>
        )}
      </div>
      {showDialog ? (
        <div
          className="ColumnNormal"
          onMouseLeave={onHover ? () => setShowDialog(false) : undefined}
          style={{
            boxShadow: "-1px 1px 4px 0px #1E1E371A",
            backgroundColor: Colors.WHITE,
            zIndex: 99,
            gap: 5,
            width: 400,
            padding: 14,
            borderRadius: 8,
            position: "absolute",
            ...(popupPosition === "bottom"
              ? { left: "5%", top: 30 }
              : { bottom: 24, right: 0 }),
          }}
        >
          <span style={{ fontSize: 14 }}>{popupTitle}</span>
          {typeof popupSubtext === "string" ? (
            <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
              {popupSubtext}
            </span>
          ) : (
            popupSubtext
          )}
        </div>
      ) : null}
    </div>
  );
};

export default PopupModalMessageTag;
