import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import "../../../../css/MassTexts.css";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AccountData,
  Event,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
  MassTextType,
  SubSelectionType,
} from "@markit/common.types";
import {
  LessThanDate,
  M4RKT,
  MARKIT_DOCS,
  NON_SUBSCRIBER_REPLY_CAMPAIGN_ID,
  calculateTextEstimatedSendTime,
  calculateTextMessageCreditsEstimate,
  dateIsCurrentYear,
  displayAutomationTriggerAction,
  getCategoryLabelName,
  getTimezone,
  handlePluralString,
  isEventExternalLink,
  isPresetNoRecipientsAutomations,
  massTextMessageConverter,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  getMassTextAttachments,
  getMassTextDeliveryAttemptedRate,
  getMassTextSendRate,
  getNumMassTextRecipients,
} from "../../../../utils/textingUtils";
import { GetLongDate } from "../../../../utils/GetLongDate";
import { GetTime } from "../../../../utils/GetTime";
import { MassTextMessagingScreenType } from "../MassTextPanel";
import ProgressBar from "@ramonak/react-progress-bar";
import CustomLinkify from "../../../Links/CustomLinkify";
import { onSnapshot } from "../../../../firebase";
import {
  getCampaignMassTextRef,
  getUserData,
} from "../../../../utils/FirebaseUtils";
import { CircularProgress } from "@mui/material";
import { MassTextMessageCompositionProps } from "./MassTextMessageComposition";
import { getEventState } from "../../../../redux/slices/eventSlice";
import MassTextsLinkAnalytics from "./MassTextLinkAnalytics";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import TextCreditsTagModal from "../Message/TextCreditsTagModal";
import { getMassTextAttachmentsLinkAnalytics } from "../../../../utils/trackingUtils";
import useAsyncOnMount from "../../../../hooks/useAsyncEffectOnMount";
import SendingTextModal from "../Message/SendingTextModal";
import PopupModalMessageTag from "../../../Containers/PopupModalMessageTag";
import ComplianceMessageTag from "../../../Compliance/ComplianceMessageTag";
import SendRateModal from "../Message/SendRateModal";
import { fetchNumFollowersNoComplianceSent } from "../../../../utils/userUtils";
import TextHoverButton from "../../../Buttons/TextHoverButton";

type MassTextsSummaryProps = MassTextMessageCompositionProps;

const MassTextsSummary = (props: MassTextsSummaryProps) => {
  const {
    campaign,
    finalRecipients,
    massText,
    screen,
    setScreen,
    theme,
    loadingRecipients,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, savedQuestions, followersData } = account;
  const { events } = useSelector(getEventState);
  const { events: userEvents } = events;
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLive, setLoadingLive] = useState<boolean>(true);
  const [liveMassText, setLiveMassText] = useState<MassText>(massText);
  const [massTextAttachments, setMassTextAttachments] = useState<
    MassTextAttachment[]
  >([]);
  const [deliveryAttemptedRate, setDeliveryAttemptedRate] = useState<number>(0);
  const [sendRate, setSendRate] = useState<number>(100);
  const [numOpensMap, setNumOpensMap] = useState<Map<string, number>>(
    new Map<string, number>()
  );
  const [numConversionsMap, setNumConversionsMap] = useState<
    Map<string, number>
  >(new Map<string, number>());
  const [selectedAttachment, setSelectedAttachment] =
    useState<MassTextAttachment>();
  const liveQueuedRef = useRef<boolean | null>(null);
  const [sendingTextModal, setSendingTextModal] = useState(false);
  const [sendRateModal, setSendRateModal] = useState(false);
  const [numRecipients, setNumRecipients] = useState(finalRecipients.length);

  const [aspectRatio, setAspectRatio] = useState(1);

  useEffect(() => {
    if (liveMassText.mediaUrl) {
      const img = new Image();
      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
      img.src = liveMassText.mediaUrl;
    }
  }, [liveMassText.mediaUrl]);

  useEffect(() => {
    // Set ref to the live queued status to use in the setInterval
    liveQueuedRef.current = liveMassText.queued;
  }, [liveMassText.queued]);

  const styles = {
    coverImage: {
      width: 260,
      borderRadius: 12,
    },

    sectionContainer: {
      paddingBlock: 14,
      backgroundColor: Colors.GRAY6,
      borderRadius: 12,
    },

    showcaseBody: {
      paddingInline: 14,
      paddingBlock: 7,
      gap: 7,
    },
  };

  const numFollowersNotCompliant = useMemo(
    () =>
      fetchNumFollowersNoComplianceSent(
        accountData,
        followersData,
        finalRecipients
      ),
    [accountData, followersData, finalRecipients]
  );

  const isTriggerText = useMemo(
    () => massText.type === MassTextType.TRIGGERTEXT,
    [massText.type]
  );

  const foundMassTextEvent = useMemo(
    () => userEvents.find((event) => event.id === massText.eventRefId),
    [massText.eventRefId, userEvents]
  );

  const numTextCredits = useMemo(
    () =>
      calculateTextMessageCreditsEstimate(
        massText.message,
        massText.mediaUrl,
        foundMassTextEvent
      ),
    [foundMassTextEvent, massText.mediaUrl, massText.message]
  );

  const hasCategories = useMemo(
    () => massText.subSelectionIds.length > 0,
    [massText.subSelectionIds.length]
  );

  const isScheduledText = useMemo(
    () =>
      LessThanDate(new Date().toISOString(), liveMassText.sentAt) &&
      liveMassText.scheduled,
    [liveMassText.scheduled, liveMassText.sentAt]
  );

  /**
   * Text is finished sending if:
   * 1. text queued and all messages have been sent on twilio
   * 2. text queued and there were no recipients on the text (event text)
   * 3. it is a trigger text
   */
  const isTextFinishedSending = useMemo(
    () =>
      (liveMassText.queued && deliveryAttemptedRate === 100) ||
      liveMassText.queued === undefined ||
      (campaign?.eventId && liveMassText.queued && numRecipients === 0) ||
      liveMassText.type === MassTextType.TRIGGERTEXT,
    [
      campaign?.eventId,
      deliveryAttemptedRate,
      numRecipients,
      liveMassText.queued,
      liveMassText.type,
    ]
  );

  const showLinkAnalytics = useMemo(
    () => isTextFinishedSending && !isScheduledText,
    [isScheduledText, isTextFinishedSending]
  );

  const massTextAttachmentsToShow = useMemo(
    () =>
      massTextAttachments.filter(
        (attachment) =>
          attachment.massTextType !== MassTextAttachmentType.TICKET_LINK
      ),
    [massTextAttachments]
  );

  const categoryLabel = useMemo(() => {
    const tickets = foundMassTextEvent ? foundMassTextEvent.customTickets : [];
    return massText.subSelectionIds.length > 0
      ? massText.subSelectionIds.length > 1 ||
        (massText.subSelectionIds.length === 1 &&
          massText.subSelectionIds[0].type === SubSelectionType.FORM_QUESTION)
        ? "Custom Selection"
        : getCategoryLabelName(
            massText.subSelectionIds[0],
            tickets,
            savedQuestions
          )
      : "No Categories";
  }, [foundMassTextEvent, massText.subSelectionIds, savedQuestions]);

  useAsyncOnMount(async () => {
    let finalUserData: AccountData = accountData;
    // for viewing other user's texting analytics
    if (massText.sentBy !== accountData.uid) {
      const userData = await getUserData(massText.sentBy);
      if (userData) {
        finalUserData = userData;
      }
    }

    const unsubscribe = onSnapshot(
      getCampaignMassTextRef(accountData.uid, massText.campaignId, massText.id),
      async (doc) => {
        const currMassText = doc.data();
        if (currMassText) {
          setLiveMassText(currMassText);
          const massTextAttachments = await getMassTextAttachments(
            finalUserData.uid,
            currMassText
          );
          setMassTextAttachments(massTextAttachments);
          await Promise.all(
            massTextAttachments.map(async (attachment) => {
              const { numOpens, numConversions } =
                await getMassTextAttachmentsLinkAnalytics(
                  finalUserData.uid,
                  currMassText,
                  attachment
                );
              setNumOpensMap(numOpensMap.set(attachment.id, numOpens));
              setNumConversionsMap(
                numConversionsMap.set(attachment.id, numConversions)
              );
            })
          );

          if (campaign) {
            const massTextRecipients = await getNumMassTextRecipients(
              finalUserData.uid,
              campaign,
              massText.id
            );
            setNumRecipients(massTextRecipients);
          }
        }
        setLoadingLive(false);
      }
    );

    let interval: NodeJS.Timeout;
    // Ignore send rate and default 100% if trigger text (TODO: get actual calculation for trigger texts)
    if (!isTriggerText) {
      // can use massText and not liveMassText since id and sentAt are the only fields used
      const massTextDeliveryAttemptedRate =
        await getMassTextDeliveryAttemptedRate(massText);
      setDeliveryAttemptedRate(massTextDeliveryAttemptedRate);
      if (
        (massTextDeliveryAttemptedRate === 100 && massText.queued) ||
        massText.queued === undefined
      ) {
        const massTextSendRate = await getMassTextSendRate(massText);
        setSendRate(massTextSendRate);
      } else {
        interval = setInterval(async () => {
          const massTextDeliveryAttemptedRate =
            await getMassTextDeliveryAttemptedRate(liveMassText);
          setDeliveryAttemptedRate(massTextDeliveryAttemptedRate);
          if (
            (massTextDeliveryAttemptedRate === 100 && liveQueuedRef.current) ||
            liveQueuedRef.current === undefined
          ) {
            const massTextSendRate = await getMassTextSendRate(liveMassText);
            setSendRate(massTextSendRate);
            if (interval) {
              clearInterval(interval);
            }
          }
        }, 5000);
      }
    }
    setLoading(false);
    return () => {
      unsubscribe();
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const renderEventItemPreview = useCallback(
    (attachment: MassTextAttachment, foundEvent: Event | undefined) => {
      return (
        <div className="AlignedRowSelect" style={{ gap: 8 }}>
          <img
            src={foundEvent?.photoURL ?? ""}
            alt={"LinkAnalyticsEventPic"}
            style={{
              width: 40,
              height: 40,
              borderRadius: 8,
            }}
          />
          <div className="ColumnNormalSelect">
            <span style={{ fontSize: 14, fontWeight: "500" }}>
              {attachment.massTextType === MassTextAttachmentType.LINK
                ? "Event Link"
                : attachment.massTextType}
            </span>
            <span className="bodySubtext">
              {foundEvent?.title ?? "Markit Event"}
            </span>
          </div>
        </div>
      );
    },
    []
  );

  const renderDateAndCredits = useMemo(
    () => (
      <>
        <span className="bodyMedium" style={theme?.PrimaryText}>
          {isTriggerText
            ? "Sent on Trigger"
            : `${GetLongDate(
                liveMassText.sentAt,
                true,
                true,
                true,
                !dateIsCurrentYear(liveMassText.sentAt)
              )}, ${GetTime(liveMassText.sentAt)} ${getTimezone()}`}
        </span>
        <TextCreditsTagModal
          message={liveMassText.message}
          mediaUrl={liveMassText.mediaUrl}
          event={foundMassTextEvent}
        />
      </>
    ),
    [
      foundMassTextEvent,
      isTriggerText,
      liveMassText.mediaUrl,
      liveMassText.message,
      liveMassText.sentAt,
      theme?.PrimaryText,
    ]
  );

  // 1. Scheduled
  // 1. Sent - Show the sent and send rate details
  // 2. In Progress - Show the progress bar while the messages finish getting delivered
  const renderTextRecipients = useMemo(
    () =>
      numRecipients > 0 || hasCategories || isTriggerText ? (
        <div className="ColumnNormal">
          <HorizontalDivider altMargin={14} />
          <div
            className="AlignedRowSpaced"
            style={{ paddingInline: 14, paddingBlock: 7 }}
          >
            <div className="AlignedRow" style={{ gap: 14 }}>
              {hasCategories ? (
                <span className="sectionTitle">{categoryLabel}</span>
              ) : (
                <div className="AlignedRow" style={{ gap: 7 }}>
                  <span className="sectionTitle">
                    {loadingRecipients ? "--" : numRecipients}
                  </span>
                  <span className="bodySubtext">
                    {isTriggerText ? "Live " : ""}
                    {handlePluralString("Recipient", numRecipients)}
                  </span>
                </div>
              )}
            </div>
            {hasCategories || numRecipients > 0 ? (
              <div
                className="AlignedRowSelect"
                onClick={() => {
                  setScreen(
                    MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY
                  );
                }}
                style={{ gap: 2 }}
              >
                <span style={{ fontSize: 14 }}>See All</span>
                <Icon icon="ion:chevron-forward" height={18} />
              </div>
            ) : null}
          </div>
        </div>
      ) : null,
    [
      categoryLabel,
      numRecipients,
      hasCategories,
      isTriggerText,
      loadingRecipients,
      setScreen,
    ]
  );

  // Three cases: 1. Scheduled Text, 2. Sent Text, 3. Sending Text
  const renderTextDetails = useMemo(
    () =>
      isScheduledText ? (
        <div className="ColumnNormal" style={styles.sectionContainer}>
          <div className="ColumnNormal" style={styles.showcaseBody}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon icon="ion:timer" height={16} color={Colors.BLUE5} />
              <span className="sectionTitle" style={{ color: Colors.BLUE5 }}>
                Scheduled
              </span>
            </div>
            {renderDateAndCredits}
          </div>
          {renderTextRecipients}
        </div>
      ) : isTextFinishedSending ? (
        <div className="ColumnNormal" style={styles.sectionContainer}>
          <div className="ColumnNormal" style={styles.showcaseBody}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon icon="ion:paper-plane" height={16} />
              <span className="sectionTitle">
                {isTriggerText && campaign
                  ? displayAutomationTriggerAction(campaign, foundMassTextEvent)
                  : "Sent"}
              </span>
            </div>
            {renderDateAndCredits}
          </div>
          {campaign && isPresetNoRecipientsAutomations(campaign.id) ? null : (
            <>
              <HorizontalDivider altMargin={14} />
              <div className="AlignedRow" style={styles.showcaseBody}>
                <span className="sectionTitle">{sendRate}%</span>
                <span className="bodySubtext">Send Rate</span>
                <Icon
                  icon="mdi:information-outline"
                  height={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => setSendRateModal(true)}
                  color={sendRate < 100 ? Colors.RED1 : Colors.GRAY1}
                />
              </div>
              {renderTextRecipients}
            </>
          )}
        </div>
      ) : (
        <div
          className="ColumnNormal"
          style={{ ...styles.sectionContainer, gap: 7 }}
        >
          <div className="ColumnNormal" style={{ paddingInline: 14, gap: 10 }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon
                icon={"ion:paper-plane"}
                height={14}
                style={{ color: Colors.BLUE5 }}
              />
              <span className="bodyMedium" style={{ color: Colors.BLUE5 }}>
                {!liveMassText.queued ? "Queued..." : "Sending..."}
              </span>
            </div>
            <ProgressBar
              completed={
                !liveMassText.queued ? 3 : Math.max(deliveryAttemptedRate, 3)
              }
              maxCompleted={100}
              isLabelVisible={false}
              bgColor={Colors.BLUE5}
              baseBgColor={Colors.WHITE}
              height="16px"
              width="100%"
              borderRadius="4px"
            />
            <div className="ColumnNormal" style={{ gap: 4 }}>
              {liveMassText.queued ? (
                <>
                  <div className="AlignedRow" style={{ gap: 7 }}>
                    <span className="bodyMedium">Estimated Send Time</span>
                    <span
                      className="bodyMedium"
                      style={{ color: Colors.GREEN2 }}
                    >
                      {calculateTextEstimatedSendTime(
                        numTextCredits * numRecipients,
                        accountData.customer.phoneType
                      )}
                    </span>
                  </div>
                  <div className="AlignedRow" style={{ gap: 4 }}>
                    <span className="smallBodySubtext">
                      Texts with more recipients may take longer to send.
                    </span>
                    <Icon
                      icon="mdi:information-outline"
                      height={13}
                      style={{ cursor: "pointer", color: Colors.GRAY1 }}
                      onClick={() => setSendingTextModal(true)}
                    />
                  </div>
                </>
              ) : null}
              <div className="AlignedRow" style={{ gap: 7 }}>
                <span className="bodySmallRegular">
                  Want faster send times?
                </span>
                <TextHoverButton
                  text="Learn More"
                  onPress={() => window.open(MARKIT_DOCS + "/sendingtimes")}
                  icon={<Icon icon="ion:chevron-forward" height={12} />}
                  iconPosition="right"
                  textStyles={{ color: Colors.BLACK }}
                />
              </div>
            </div>
          </div>
          {renderTextRecipients}
        </div>
      ),
    [
      accountData.customer.phoneType,
      campaign,
      deliveryAttemptedRate,
      foundMassTextEvent,
      isScheduledText,
      isTextFinishedSending,
      isTriggerText,
      liveMassText.queued,
      numRecipients,
      numTextCredits,
      renderDateAndCredits,
      renderTextRecipients,
      sendRate,
      styles.sectionContainer,
      styles.showcaseBody,
    ]
  );

  const renderShowcaseBox = (attachment: MassTextAttachment) => {
    const foundEvent = userEvents.find(
      (event) => event.id === attachment.eventId
    );
    const hideConversions =
      foundEvent && isEventExternalLink(foundEvent.eventType);
    return (
      <div
        key={attachment.id}
        onClick={() => {
          setSelectedAttachment(attachment);
          setScreen(MassTextMessagingScreenType.LINK_ANALYTICS);
        }}
        className="MassTextsSectionContainer"
        style={{
          paddingBlock: 14,
          paddingInline: 0,
          pointerEvents: showLinkAnalytics ? "all" : "none",
        }}
      >
        <div className="AlignedRowSpacedSelect" style={{ paddingInline: 14 }}>
          {renderEventItemPreview(attachment, foundEvent)}
          {showLinkAnalytics ? (
            <Icon
              icon="ion:chevron-forward-outline"
              height={20}
              color={Colors.GRAY1}
            />
          ) : null}
        </div>
        {showLinkAnalytics ? (
          <>
            <HorizontalDivider altMargin={14} />
            <div
              className="AlignedRowSelect"
              style={{ gap: 24, paddingInline: 14 }}
            >
              <div className="ColumnNormalSelect">
                <span className="sectionTitle">
                  {numOpensMap.get(attachment.id) || 0}
                </span>
                <span className="smallBodySubtext">
                  Click
                  {numOpensMap.get(attachment.id) === 1 ? "" : "s"}
                </span>
              </div>
              {!hideConversions ? (
                <div className="ColumnNormalSelect">
                  <span className="sectionTitle">
                    {numConversionsMap.get(attachment.id) || 0}
                  </span>
                  <span className="smallBodySubtext">
                    Conversion
                    {numConversionsMap.get(attachment.id) === 1 ? "" : "s"}
                  </span>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    );
  };

  const renderMessage = useMemo(
    () => (
      <div className="ColumnCenter" style={{ gap: 14 }}>
        <span className="sectionTitle" style={theme?.PrimaryText}>
          Message
        </span>
        <CustomLinkify>
          <span
            className="bodySubtext"
            style={{
              whiteSpace: "break-spaces", // TODO (jonathan): text here to fix overflow text
            }}
          >
            {massTextMessageConverter(
              liveMassText.message,
              massTextAttachments
            )}
          </span>
        </CustomLinkify>
        <div style={{ paddingBlock: 14 }}>
          {massText.campaignId === NON_SUBSCRIBER_REPLY_CAMPAIGN_ID ? (
            <PopupModalMessageTag
              message={`${M4RKT}/u/${accountData.username}/subscribe`}
              popupTitle="Profile Link Included"
              popupSubtext="The link to subscribe to your profile will be automatically appended to the end of your message so that people can subscribe."
            />
          ) : numFollowersNotCompliant > 0 ||
            massText.type === MassTextType.TRIGGERTEXT ? (
            <ComplianceMessageTag
              alternateMessage={
                massText.subSelectionIds.length > 0
                  ? "If this is the first text someone has been sent, this compliance message must be included"
                  : massText.type === MassTextType.TRIGGERTEXT
                  ? `This additional default message ${
                      massText.eventRefId ? "may" : "will"
                    } be added to this text when sent for the first time.`
                  : `${numFollowersNotCompliant} of your selected recipients have not received a compliance message. For these recipients, this additional default message will be added to your text.`
              }
              mayBeIncluded={massText.eventRefId !== ""}
            />
          ) : undefined}
        </div>
        {massText.mediaUrl ? (
          <img
            src={massText.mediaUrl}
            alt={"text media"}
            style={{ ...styles.coverImage, ...{ aspectRatio: aspectRatio } }}
          />
        ) : null}
      </div>
    ),
    [
      accountData.username,
      aspectRatio,
      liveMassText.message,
      massText.campaignId,
      massText.eventRefId,
      massText.mediaUrl,
      massText.subSelectionIds.length,
      massText.type,
      massTextAttachments,
      numFollowersNotCompliant,
      styles.coverImage,
      theme?.PrimaryText,
    ]
  );

  return (
    <>
      {!selectedAttachment ||
      screen === MassTextMessagingScreenType.SEND_SUMMARY ? (
        <div
          className="ColumnNormal"
          style={{ gap: 20, padding: 20, height: "100%" }}
        >
          {!loadingLive && !loading ? (
            <>
              {renderTextDetails}
              {!isTriggerText ? (
                <div className="ColumnNormal" style={{ gap: 14 }}>
                  <span className="sectionTitle">
                    {isTextFinishedSending ? "Link Analytics" : "Links"}
                  </span>
                  {massTextAttachmentsToShow.length > 0 ? (
                    massTextAttachmentsToShow.map((attachment) =>
                      renderShowcaseBox(attachment)
                    )
                  ) : (
                    <div
                      style={{
                        ...styles.sectionContainer,
                        paddingInline: 14,
                        paddingBlock: 24,
                      }}
                    >
                      <span className="bodySubtext">No Links to Show</span>
                    </div>
                  )}
                </div>
              ) : null}
              {renderMessage}
              {/** TODO: Hidden after new automation stuff, re-add it down the road when we want this functionality back */}
              {/* {hasCategories ? (
                <MassTextCatchUp
                  massText={liveMassText}
                  catchUpChecked={liveMassText.catchUpEnabled}
                  setCatchUpChecked={(catchUp: boolean) =>
                    updateMassTextSettings({ catchUpEnabled: catchUp })
                  }
                  theme={theme}
                />
              ) : null} */}
              <div style={{ paddingBlock: 50 }} />
            </>
          ) : (
            <div className="Centering" style={{ height: "80%" }}>
              <CircularProgress
                style={{ color: Colors.GRAY1, marginTop: 14 }}
                size={24}
              />
            </div>
          )}
        </div>
      ) : (
        <MassTextsLinkAnalytics
          massText={liveMassText}
          massTextAttachment={selectedAttachment}
        />
      )}
      <SendingTextModal
        modalVisible={sendingTextModal}
        setModalVisible={setSendingTextModal}
        totalNumCredits={numTextCredits * numRecipients}
      />
      <SendRateModal
        modalVisible={sendRateModal}
        setModalVisible={setSendRateModal}
        sendRate={sendRate}
      />
    </>
  );
};

export default MassTextsSummary;
