import { useMemo } from "react";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useSelector } from "react-redux";
import { TwilioPhoneVerificationStatus } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { userPhoneVerificationStatus } from "@markit/common.utils";

type VerificationAccountStatusItemProps = {
  onPress?: () => void;
  expanded?: boolean;
};

const VerificationAccountStatusItem = (
  props: VerificationAccountStatusItemProps
) => {
  const { onPress, expanded } = props;
  const { accountData } = useSelector(getAccountState).account;

  const accountStatus = useMemo(
    () => userPhoneVerificationStatus(accountData),
    [accountData]
  );

  const accountStatusItems = useMemo(() => {
    if (accountStatus === TwilioPhoneVerificationStatus.APPROVED) {
      return {
        icon: "ion:checkmark-circle",
        backgroundColor: "rgba(102, 187, 106, 0.1)",
        color: Colors.GREEN2,
        borderColor: Colors.GREEN2,
        text: "Approved",
      };
    } else if (accountStatus === TwilioPhoneVerificationStatus.REJECTED) {
      return {
        icon: "ion:close-circle",
        backgroundColor: "rgba(239, 68, 68, 0.1)",
        color: Colors.RED3,
        borderColor: Colors.RED3,
        text: "Rejected",
      };
    } else {
      return {
        icon: "mdi:dots-circle",
        backgroundColor: "rgba(239, 149, 61, 0.1)",
        color: Colors.BLACK,
        borderColor: Colors.ORANGE1,
        text: "In Review",
      };
    }
  }, [accountStatus]);

  return (
    <div
      className={onPress ? "AlignedRowSelect" : "AlignedRow"}
      onClick={onPress}
      style={{
        minWidth: onPress ? undefined : 130,
        marginBottom: onPress ? 10 : 0,
        paddingInline: 12,
        paddingBlock: 8,
        borderRadius: 8,
        gap: 11,
        backgroundColor: accountStatusItems.backgroundColor,
        border: `1px solid ${accountStatusItems.borderColor}`,
      }}
    >
      <Icon
        icon={accountStatusItems.icon}
        height={19}
        color={accountStatusItems.color}
      />
      <div className="ColumnNormal" style={{ gap: 2 }}>
        <span className="bodyMedium">{accountStatusItems.text}</span>
        <span className="smallBodySubtext">
          {expanded
            ? accountStatus === TwilioPhoneVerificationStatus.REJECTED
              ? "Your account was rejected. Contact support to resubmit."
              : "This feature is temporarily unavailable while your account is under review."
            : "Account Status"}
        </span>
      </div>
    </div>
  );
};

export default VerificationAccountStatusItem;
