import { useDispatch, useSelector } from "react-redux";
import {
  accountActions,
  changeSignedAgreement,
  getAccountState,
} from "../redux/slices/accountSlice";
import { useCallback, useContext, useMemo } from "react";
import { AccountData, PhoneInfo } from "@markit/common.types";
import { MixpanelContext } from "../context/AnalyticsService";
import { UserCredential } from "firebase/auth";
import {
  Batch,
  getPhoneNumberRef,
  getUserRef,
  getUserSnap,
} from "../utils/FirebaseUtils";
import { generate } from "shortid";
import { getUrlParamState } from "../redux/slices/urlParamSlice";
import { getUrlParamsFromString } from "../redux/sagas/initializationSaga";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeEmptyAccount } from "@markit/common.utils";
import { NavigationId } from "../navigation/AppParamList";
import { OnboardingStage } from "../screens/Login/LoginOnboarding";

export const useLogin = () => {
  const { redirectPath, currentRoleTicket, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mixpanel = useContext(MixpanelContext);
  const { referral } = useSelector(getUrlParamState);

  const defaultSubscriber = useMemo(
    () => searchParams.has("subscriber"),
    [searchParams]
  );

  // Handles the case of directing to accept an event role
  // TODO (jonathan): noticed logic here is a bit messy. Don't really understand why finishTempCreation has conditional logic
  //                  on whether it's logging in or signing up. Feels like that should get moved to within loginWithCredential
  const finishTempCreation = useCallback(
    async (userData: AccountData, isLogin: boolean) => {
      if (currentRoleTicket && isLogin) {
        // Take user to confirmation page which will have a button telling them to accept role
        // did it this way since the confirmation page will figure out if this account already has a role or not
        // to avoid getting double roles
        window.location.replace(
          `https://markitai.com/e/${currentRoleTicket.eventId}/i/${userData.uid}?ticketId=${currentRoleTicket.id}`
        );
        dispatch(accountActions.setCurrentRoleTicket(undefined));
      } else {
        navigate(NavigationId.ONBOARDING, {
          state: {
            defaultStage:
              defaultSubscriber || userData.email
                ? OnboardingStage.PROFILE_SETUP
                : undefined,
          },
        });
      }
    },
    [currentRoleTicket, dispatch, navigate, defaultSubscriber]
  );

  const loginWithCredential = async (
    credential: UserCredential,
    signIn: boolean,
    tempFullName?: string
  ) => {
    if (credential.user !== null) {
      const uid = credential.user.uid;
      const accountSnap = await getUserSnap(uid);
      const accountData = accountSnap.data();

      if (accountData) {
        // initialize mixpanel user properties if not created already
        mixpanel.identify(accountData.uid);
        mixpanel.people.set_once({
          $email: accountData.email,
          $phone: accountData.phoneNumber,
          $name: accountData.fullName,
          Followers: followersData.length,
          PaidTicketsSold: 0,
          TotalEventRevenue: 0,
          NetEventRevenue: 0,
          SubscriptionState: "INACTIVE",
          SubscriptionMRR: 0,
        });
        // change signed agreement to true if false for existing user
        if (!accountData.signedAgreement) {
          dispatch(changeSignedAgreement(true, uid));
        }
        // if account has been created for user, login
        if (credential.user) {
          mixpanel.identify(uid);
        }
        mixpanel.track("Webapp: User Login");
        mixpanel.people_increment({ Logins: 1 });
        mixpanel.people.set({
          "Last Login": new Date().toISOString(),
        });
        dispatch(accountActions.login(accountData));
        // don't want to redirect folks on rsvp/follow/external link
        if (signIn) {
          if (currentRoleTicket) {
            await finishTempCreation(
              accountData,
              !accountData.haveNotFinishedInitialStartup
            );
          } else if (referral !== "" && accountData.customer.phone === "") {
            navigate("/subscription");
          } else if (accountData.haveNotFinishedInitialStartup) {
            // if account has not set own username (finished sign up flow)
            navigate(NavigationId.ONBOARDING, {
              state: { defaultStage: OnboardingStage.PROFILE_SETUP },
            });
          } else if (redirectPath !== "") {
            // need to reset the url params
            const params = getUrlParamsFromString(redirectPath);
            navigate(redirectPath, {
              replace: true,
              state: {
                eventId: params.e,
                username: params.u,
                ticketId: params.ticketId,
                qrCodeId: params.qrCodeId,
                userId: params.i,
              },
            });
          } else if (accountData.inCreatorMode) {
            navigate("/home", { replace: true });
          } else {
            navigate("/", { replace: true });
          }

          window.scrollTo(0, 0);
        }
      } else {
        // if an account has not been created yet for the user, create account
        // create all account data if necessary
        const email = credential.user.email;
        const phoneNumber = credential.user.phoneNumber;
        const formattedPhoneNumber = phoneNumber || "";
        if (email || phoneNumber) {
          const batch = new Batch();

          const data: AccountData = {
            ...makeEmptyAccount(),
            uid: uid,
            fullName: tempFullName ?? "", // defined when account creation when rsvp/follow
            email: email ?? "",
            username: generate().toLowerCase(),
            inCreatorMode: signIn && !defaultSubscriber, // true if signIn and new acount and not on subscriber flow, false on rsvp/follow/external link
            phoneNumber: formattedPhoneNumber,
            haveNotFinishedStartup: true,
            haveNotFinishedInitialStartup: true,
            signedAgreement: true,
          };

          const userRef = getUserRef(uid);
          batch.set(userRef, data);

          if (formattedPhoneNumber !== "") {
            const phoneNumberRef = getPhoneNumberRef(formattedPhoneNumber);

            const phoneInfo: PhoneInfo = {
              lastFreeCreator: "",
              phoneNumber: formattedPhoneNumber,
              userId: uid,
              sentCompliance: false,
              sentComplianceFreeCreatorPhone: false,
              optOut: false,
              optOutFreeCreatorPhone: false,
              optOutTimestamp: "",
            };
            batch.set(phoneNumberRef, phoneInfo);
          }

          // set completedCreatorSetupTimestamp to true
          batch.update(userRef, {
            completedCreatorSetupTimestamp: new Date().toISOString(),
          });

          try {
            await batch.commit();
          } catch (e: any) {
            console.log(e.message);
          }

          mixpanel.track("Webapp: User Created New Account", {
            phone_number: phoneNumber,
            email: email,
            full_name: data.fullName,
            sign_up: "web-app",
          });
          // initialize mixpanel user properties
          mixpanel.register(uid);
          mixpanel.identify(data.uid);
          mixpanel.people.set_once({
            $email: data.email,
            $phone: data.phoneNumber,
            $name: data.fullName,
            Following: 0,
            Followers: 0,
            PaidTicketsSold: 0,
            TotalEventRevenue: 0,
            NetEventRevenue: 0,
            SubscriptionState: "INACTIVE",
            SubscriptionMRR: 0,
          });

          // don't want to redirect folks on rsvp/follow/external link
          if (signIn) {
            await finishTempCreation(data, false);
          }
        } else {
          alert("An error has occurred");
        }
      }
    }

    return false;
  };

  return { loginWithCredential };
};
