import { isDesktop } from "react-device-detect";
import { useCallback } from "react";
import "../../../css/GlobalStyles.css";
import {
  PhoneOnboardingStage,
  VerificationAccountType,
} from "../../../screens/Subscription/SubscriptionPhoneOnboarding";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { Colors } from "../../../utils/colors";
import { useTheme } from "../../../hooks/useTheme";
import { Icon } from "@iconify/react";
import RectangleButton from "../../Buttons/RectangleButton";

type PhoneOnboardingSelectTypeProps = {
  selectedAccountType: VerificationAccountType;
  setSelectedAccountType: (accountType: VerificationAccountType) => void;
  setOnboardingStage: (stage: PhoneOnboardingStage) => void;
};

const PhoneOnboardingSelectType = (props: PhoneOnboardingSelectTypeProps) => {
  const { selectedAccountType, setSelectedAccountType, setOnboardingStage } =
    props;
  const { theme } = useTheme();

  const renderSectionItem = useCallback(
    (itemType: VerificationAccountType) => (
      <StandardBorderedContainer
        containerStyles={{
          height: 63,
          display: "flex",
          alignItems: "center",
          borderRadius: 14,
          border: `${
            selectedAccountType === itemType
              ? `2px solid ${Colors.BLACK}`
              : `1.5px solid ${theme.DividerColor.borderColor}`
          }`,
          cursor: "pointer",
        }}
        onPress={() => setSelectedAccountType(itemType)}
      >
        <div
          className="AlignedRowSpaced"
          style={{ paddingInline: 14, width: "100%" }}
        >
          <span className="bodyMedium">
            {itemType === VerificationAccountType.BUSINESS
              ? "Business/Organization"
              : "Individual"}
          </span>
          {selectedAccountType === itemType ? (
            <Icon
              icon={"ion:checkmark-circle"}
              height={25}
              color={Colors.BLACK}
            />
          ) : null}
        </div>
      </StandardBorderedContainer>
    ),
    [
      selectedAccountType,
      setSelectedAccountType,
      theme.DividerColor.borderColor,
    ]
  );

  return (
    <div
      className="ColumnNormal"
      style={{ width: isDesktop ? 330 : undefined, gap: 24 }}
    >
      <div className="ColumnNormal" style={{ gap: 14 }}>
        {renderSectionItem(VerificationAccountType.BUSINESS)}
        {renderSectionItem(VerificationAccountType.INDIVIDUAL)}
      </div>
      <RectangleButton
        buttonLabel={`Continue as ${
          selectedAccountType === VerificationAccountType.BUSINESS
            ? "Business/Organization"
            : "Individual"
        }`}
        containerStyles={{ paddingInline: 10, paddingBlock: 14 }}
        altTextColor={Colors.WHITE}
        altColor={Colors.BLACK}
        onPress={() => setOnboardingStage(PhoneOnboardingStage.ENTER_INFO)}
      />
    </div>
  );
};

export default PhoneOnboardingSelectType;
