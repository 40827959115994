import "./css/App.css";
import { Fragment, useContext, useEffect, useLayoutEffect } from "react";
import { useOnMount } from "./hooks/useOnMount";
import { MixpanelContext } from "./context/AnalyticsService";
import { Route, Routes, useLocation } from "react-router-dom";
import EventRoute from "./routes/EventRoute";
import ProfileRoute from "./routes/ProfileRoute";
import SubscriptionRoute from "./routes/SubscriptionRoute";
import ExploreRoute from "./routes/ExploreRoute";
import CommunityPage from "./screens/CommunityPage";
import LoadingScreen from "./screens/LoadingScreen";
import AdminDashboardRoute from "./routes/AdminDashboardRoute";
import { CreateEvent } from "./screens/CreateEvent/CreateEvent";
import {
  LandingPageNavigationId,
  NavigationId,
} from "./navigation/AppParamList";
import { useDispatch, useSelector } from "react-redux";
import { accountActions, getAccountState } from "./redux/slices/accountSlice";
import { LightTheme, useTheme } from "./hooks/useTheme";
import HomeRoute from "./routes/HomeRoute";
import CreateExternalLink from "./screens/CreateEvent/CreateExternalLink";
import { Helmet } from "react-helmet";
import ScrollToTop from "./utils/ScrollToTop";
import ImportContacts from "./screens/Import/ImportContacts";
import { useInitialAppLoad } from "./hooks/useInitialAppLoad";
import EventbriteIntegration from "./screens/Integrations/EventbriteIntegration";
import CampaignDashboard from "./screens/Campaigns/CampaignDashboard";
import ConfirmActionPopup from "./components/Containers/ConfirmPopups/ConfirmActionPopup";
import AudienceListDashboard from "./screens/AudienceLists/AudienceListDashboard";
import DefaultLandingPage from "./screens/LandingPage/DefaultLandingPage";
import LoginWelcome from "./screens/Login/LoginWelcome";
import LoginOnboarding from "./screens/Login/LoginOnboarding";
import WelcomeLandingPage from "./screens/WelcomeLandingPage";
import PricingPage from "./screens/LandingPage/PricingPage";
import { MarkitPlusModal } from "./components/Subscription/MarkitPlusModal";
import SubscriptionPhoneOnboarding from "./screens/Subscription/SubscriptionPhoneOnboarding";

/**
 * Documentation of the different webapp URLs
 * https://docs.google.com/document/d/1E_lDN3HcWl1hQFY-UujdHVw2VtRTXMEjihSoSBm9Mwg
 *
 */
const App = () => {
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const { account } = useSelector(getAccountState);
  const { currentRoleTicket, currentEventFormQuestions } = account;
  const { pathname } = useLocation();
  // If we need to put app under maintenance
  const underMaintenance = false;
  const { theme } = useTheme();

  useInitialAppLoad();
  useOnMount(() => {
    mixpanel.track("Viewed Webapp Link");
  });

  // called on unmount when leaving sign in or onboarding route and clears the currentRoleTicket if it exists,
  // unless the user presses a certain button on the screen
  useEffect(() => {
    if (
      currentRoleTicket &&
      !pathname.includes(NavigationId.SIGN_IN) &&
      !pathname.includes(NavigationId.ONBOARDING)
    ) {
      // for clearing accepting event role data
      dispatch(accountActions.setCurrentRoleTicket(undefined));
    }
    if (
      currentEventFormQuestions.length > 0 &&
      !pathname.includes("/create") &&
      !pathname.includes("/edit") &&
      !pathname.includes("/createLink") &&
      !pathname.includes("/editLink") &&
      !pathname.includes(NavigationId.WELCOME)
    ) {
      // for clearing the event selected saved form questions on event creation
      dispatch(accountActions.resetCurrentEventFormQuestions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // set the background color beyond the set window
  useLayoutEffect(() => {
    document.body.style.backgroundColor =
      theme === LightTheme ? "white" : "black";
  }, [theme, pathname]);

  // TODO (jasonx): turn this into something more typesafe
  if (underMaintenance) {
    return <LoadingScreen underMaintenance={underMaintenance} />;
  } else {
    return (
      <>
        <Helmet>
          <title>Markit AI</title>
          <meta name="og:title" content={"Markit"} />
          <meta name="og:description" content={""} />
        </Helmet>
        <ScrollToTop>
          <Routes>
            <Route path={NavigationId.HOME} element={<HomeRoute />} />
            <Route
              path={NavigationId.SUBSCRIPTION_PLAN}
              element={<SubscriptionRoute />}
            />
            <Route
              path="secondary-onboarding"
              element={<SubscriptionPhoneOnboarding />}
            />
            <Route path="/e/:eventId/*" element={<EventRoute />} />
            <Route
              path="/a/:audienceListId"
              element={<AudienceListDashboard />}
            />
            <Route path="/c/:campaignId" element={<CampaignDashboard />} />
            <Route path={"/create"} element={<CreateEvent />} />
            <Route path={"/createLink"} element={<CreateExternalLink />} />
            <Route path={NavigationId.IMPORT} element={<ImportContacts />} />
            <Route
              path={NavigationId.INTEGRATIONS}
              element={<EventbriteIntegration />}
            />
            <Route path="/u/:username/*" element={<ProfileRoute />} />
            <Route path="/explore/*" element={<ExploreRoute />} />
            <Route
              path={NavigationId.ADMIN_DASHBOARD}
              element={<AdminDashboardRoute />}
            />
            <Route path="/c/:communityId" element={<CommunityPage />} />

            {/** Login Routes */}
            <Fragment>
              <Route path={NavigationId.SIGN_IN} element={<LoginWelcome />} />
              <Route
                path={NavigationId.ONBOARDING}
                element={<LoginOnboarding />}
              />
              <Route
                path={NavigationId.WELCOME}
                element={<WelcomeLandingPage />}
              />
            </Fragment>
            {/** Landing Page Routes */}
            <Fragment>
              <Route
                path={LandingPageNavigationId.PRICING}
                element={<PricingPage />}
              />
              <Route path="*" element={<DefaultLandingPage />} />
            </Fragment>
          </Routes>
        </ScrollToTop>
        <ConfirmActionPopup />
        <MarkitPlusModal />
      </>
    );
  }
};

export default App;
