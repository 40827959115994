import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../../components/Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RectangleButton from "../../../components/Buttons/RectangleButton";
import { NavigationId } from "../../../navigation/AppParamList";
import {
  hasSubscription,
  userPhoneVerificationStatus,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { OnboardingStage } from "../../Login/LoginOnboarding";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { TwilioPhoneVerificationStatus } from "@markit/common.types";

const FinishOnboardingPanel = () => {
  const navigate = useNavigate();
  const { accountData } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);

  useEffect(() => {
    if (
      userPhoneVerificationStatus(accountData) !==
      TwilioPhoneVerificationStatus.UNVERIFIED
    ) {
      navigate("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, accountData.customer.phoneVerification.status]);

  const finishOnboardingOnPress = useCallback(() => {
    if (hasSubscription(accountData)) {
      mixpanel.track("Pressed Finish Phone Onboarding", {
        distinct_id: accountData.uid,
      });
      navigate(NavigationId.SECONDARY_ONBOARDING);
    } else {
      navigate(NavigationId.ONBOARDING, {
        state: { defaultStage: OnboardingStage.PROFILE_SETUP },
      });
    }
  }, [accountData, mixpanel, navigate]);

  const renderItemRow = useCallback(
    (
      title: string,
      description: string,
      showLine: boolean,
      showCheck?: boolean
    ) => (
      <div
        className="AlignedRow"
        style={{ alignItems: "flex-start", opacity: showCheck ? 0.6 : 1 }}
      >
        <div className="ColumnNormal" style={{ alignItems: "center" }}>
          <Icon
            icon={
              showCheck
                ? "ion:checkmark-circle"
                : "mdi:checkbox-blank-circle-outline"
            }
            height={16}
            color={showCheck ? Colors.GREEN2 : Colors.GRAY2}
          />
          {showLine ? (
            <div
              style={{
                height: 54,
                width: 1,
                backgroundColor: Colors.GRAY2,
              }}
            />
          ) : null}
        </div>
        <div className="ColumnNormal" style={{ paddingLeft: 14, gap: 5 }}>
          <span
            className="sectionTitle"
            style={{ color: showCheck ? Colors.GRAY2 : Colors.BLACK }}
          >
            {title}
          </span>
          <span className="smallBodySubtext">{description}</span>
        </div>
      </div>
    ),
    []
  );

  return (
    <div className="ColumnNormal PreventScroll" style={{ height: "100%" }}>
      <div className="ColumnCentering" style={{ margin: "auto", gap: 30 }}>
        <div className="ColumnCentering" style={{ gap: 20 }}>
          <StandardBorderedContainer
            containerStyles={{
              backgroundColor: Colors.WHITE1,
              borderRadius: 16,
              width: 73,
              height: 73,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon icon={"ion:warning"} height={45} color={Colors.BLACK} />
          </StandardBorderedContainer>
          <span
            style={{
              fontSize: 20,
              fontWeight: 500,
              width: 345,
              textAlign: "center",
            }}
          >
            You must complete your onboarding to access your account
          </span>
        </div>
        <div className="ColumnNormal" style={{ width: 245 }}>
          {renderItemRow(
            "Basic Onboarding",
            "Profile details and plan selection",
            hasSubscription(accountData),
            hasSubscription(accountData)
          )}
          {hasSubscription(accountData)
            ? renderItemRow(
                "Enter Additional Information",
                "Required for all Markit+ users to complete to access their account.",
                false,
                false
              )
            : null}
        </div>
        <RectangleButton
          buttonLabel="Finish Onboarding"
          iconRight={
            <Icon icon={"ion:arrow-forward"} height={17} color={Colors.WHITE} />
          }
          containerStyles={{ paddingInline: 10, paddingBlock: 14 }}
          altTextColor={Colors.WHITE}
          altColor={Colors.BLACK}
          onPress={finishOnboardingOnPress}
        />
      </div>
    </div>
  );
};

export default FinishOnboardingPanel;
