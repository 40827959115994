import "../../css/Subscription.css";
import "../../css/GlobalStyles.css";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { Colors } from "../../utils/colors";
import {
  OVERFLOW_RATE,
  OVERFLOW_RATE_BUSINESS,
  OVERFLOW_RATE_CREATOR,
  PlanTypeV4,
  SubscriptionPlanType,
  ThemeType,
} from "@markit/common.types";
import { convertToMoney, MARKIT_TEXTING_CREDITS } from "@markit/common.utils";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { ReactNode, useMemo } from "react";
import { useTheme } from "../../hooks/useTheme";
import { Icon } from "@iconify/react";
import TextHoverButton from "../Buttons/TextHoverButton";

type SubscriptionPlanCardProps = {
  cardButton: ReactNode;
  subscriptionPlan: SubscriptionPlanType;
  isSelected: boolean;
  selectOnPress?: () => void;
  homePage?: boolean; // dark mode
  isDesktopSize?: boolean;
};

const SubscriptionPlanCard = (props: SubscriptionPlanCardProps) => {
  const {
    cardButton,
    subscriptionPlan,
    isSelected,
    selectOnPress,
    homePage,
    isDesktopSize,
  } = props;
  const { theme } = useTheme();

  const styles = {
    bodyMediumBold: {
      fontWeight: 500,
      fontSize: 14,
    },
    labelSubtext: {
      fontSize: 12,
      fontWeight: 500,
      color: Colors.GRAY3,
    },
    sectionContainer: { gap: 10, paddingInline: 14 },
  };

  const CardHorizontalDivider = useMemo(
    () => (
      <HorizontalDivider
        altMargin={14}
        eventTheme={homePage ? ThemeType.Dark : undefined}
      />
    ),
    [homePage]
  );

  const previousSubscriptionOption = useMemo(
    () =>
      subscriptionPlan.name === PlanTypeV4.BUSINESS.name
        ? PlanTypeV4.CREATOR
        : subscriptionPlan.name === PlanTypeV4.CREATOR.name
        ? PlanTypeV4.BASIC
        : PlanTypeV4.FREE,
    [subscriptionPlan.name]
  );

  const learnMoreAboutCredits = useMemo(
    () => (
      <TextHoverButton
        text="Learn more about credits"
        onPress={() => window.open(MARKIT_TEXTING_CREDITS)}
        icon={
          <Icon
            icon="ion:chevron-right"
            height={10}
            style={{ paddingTop: 1 }}
            color={Colors.GRAY3}
          />
        }
        iconPosition="right"
        textStyles={{ color: Colors.GRAY3 }}
      />
    ),
    []
  );

  const freeSubscriptionCard = (
    <>
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Texting</span>
        <div className="ColumnNormal" style={{ gap: 2 }}>
          <span style={styles.bodyMediumBold}>
            {PlanTypeV4.FREE.baselineLimit} Texting credits / mo
          </span>
          {learnMoreAboutCredits}
        </div>
        <span style={styles.bodyMediumBold}>Scheduled texting</span>
        <span style={styles.bodyMediumBold}>Campaigns & automations</span>
        <span style={styles.bodyMediumBold}>Opens / conversion tracking</span>
      </div>
      {CardHorizontalDivider}
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Audience Features</span>
        <span style={styles.bodyMediumBold}>Add single contact</span>
        <span style={styles.bodyMediumBold}>Custom contact data fields</span>
        <span style={styles.bodyMediumBold}>Favorites list</span>
      </div>
      {CardHorizontalDivider}
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Growth Features</span>
        <span style={styles.bodyMediumBold}>Shareable URL and QR Code</span>
        <span style={styles.bodyMediumBold}>Data capture forms</span>
        <span style={styles.bodyMediumBold}>Link shorteners</span>
        <span style={styles.bodyMediumBold}>Unlimited event creation</span>
        <span style={styles.bodyMediumBold}>Paid ticketing & promo codes</span>
        <span style={styles.bodyMediumBold}>
          Apple, Google, and Cashapp Pay
        </span>
      </div>
    </>
  );

  const basicSubscriptionCard = (
    <>
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Texting</span>
        <div className="ColumnNormal" style={{ gap: 2 }}>
          <span style={styles.bodyMediumBold}>
            {OVERFLOW_RATE} cents per texting credit
          </span>
          {learnMoreAboutCredits}
        </div>
        <span style={styles.bodyMediumBold}>Dedicated Markit phone number</span>
        <span style={styles.bodyMediumBold}>Two-way text conversations</span>
        <span style={styles.bodyMediumBold}>Customize welcome flow texts</span>
        <span style={styles.bodyMediumBold}>Branded contact card</span>
      </div>
      {CardHorizontalDivider}
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Audience Features</span>
        <span style={styles.bodyMediumBold}>3 Audience lists</span>
      </div>
      {CardHorizontalDivider}
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Growth Features</span>
        <span style={styles.bodyMediumBold}>
          &quot;Text my Number&quot; to sign up
        </span>
      </div>
    </>
  );

  const creatorSubscriptionCard = (
    <>
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Texting</span>
        <div className="ColumnNormal" style={{ gap: 2 }}>
          <span style={styles.bodyMediumBold}>
            {OVERFLOW_RATE_CREATOR} cents per texting credit
          </span>
          {learnMoreAboutCredits}
        </div>
        <span style={styles.bodyMediumBold}>
          {PlanTypeV4.CREATOR.baselineLimit.toLocaleString()} Free credits / mo
        </span>
        <span style={styles.bodyMediumBold}>Text images and media</span>
      </div>
      {CardHorizontalDivider}
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Audience Features</span>
        <span style={styles.bodyMediumBold}>Paid subscriber memberships</span>
        <span style={styles.bodyMediumBold}>10 Audience lists</span>
      </div>
      {CardHorizontalDivider}
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Growth Features</span>
        <span style={styles.bodyMediumBold}>Import CSV</span>
        <span style={styles.bodyMediumBold}>Full integration access</span>
      </div>
    </>
  );

  const businessSubscriptionCard = (
    <>
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Texting</span>
        <div className="ColumnNormal" style={{ gap: 2 }}>
          <span style={styles.bodyMediumBold}>
            {OVERFLOW_RATE_BUSINESS} cent per texting credit
          </span>
          {learnMoreAboutCredits}
        </div>
        <span style={styles.bodyMediumBold}>
          {PlanTypeV4.BUSINESS.baselineLimit.toLocaleString()} Free credits / mo
        </span>
        <span style={styles.bodyMediumBold}>Custom phone number area code</span>
        <span style={styles.bodyMediumBold}>Faster send times</span>
      </div>
      {CardHorizontalDivider}
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>Audience Features</span>
        <span style={styles.bodyMediumBold}>Unlimited audience lists</span>
      </div>
      {CardHorizontalDivider}
      <div className="ColumnNormal" style={styles.sectionContainer}>
        <span style={styles.labelSubtext}>More</span>
        <span style={styles.bodyMediumBold}>
          White glove onboarding & support
        </span>
        <span style={styles.bodyMediumBold}>API Access</span>
      </div>
    </>
  );

  return (
    <StandardBorderedContainer
      containerStyles={{
        minHeight: 720,
        width: isDesktopSize ? 290 : undefined,
        paddingBlock: 14,
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: homePage ? Colors.GRAY10 : Colors.WHITE,
        border:
          !homePage && isSelected
            ? `2px solid ${Colors.BLACK1}`
            : `1px solid ${
                homePage ? Colors.BLACK1 : theme.DividerColor.borderColor
              }`,
        cursor: selectOnPress ? "pointer" : "default",
      }}
      onPress={selectOnPress}
    >
      <div className="ColumnNormal">
        <div
          className="ColumnCentering"
          style={{
            height: 110,
            gap: 14,
            paddingBottom: 10,
            paddingInline: 14,
          }}
        >
          <div
            style={{
              paddingInline: 7,
              paddingBlock: 4,
              borderRadius: 8,
              backgroundColor: homePage ? Colors.GRAY7 : Colors.GRAY6,
            }}
          >
            <span style={styles.bodyMediumBold}>{subscriptionPlan.name}</span>
          </div>
          {subscriptionPlan.price > 0 ? (
            <span style={{ fontSize: 24, fontWeight: 500 }}>
              {convertToMoney(subscriptionPlan.price / 100).slice(0, -3)}
              <span style={styles.labelSubtext}> / mo</span>
            </span>
          ) : (
            <span style={{ fontSize: 24, fontWeight: 500 }}>$0</span>
          )}
          <span style={{ fontSize: 12, fontWeight: 500, textAlign: "center" }}>
            {subscriptionPlan.name === PlanTypeV4.FREE.name
              ? "Text for free with usage restrictions."
              : subscriptionPlan.name === PlanTypeV4.BASIC.name
              ? "Two-way texting, dedicated number. Great for testing out Markit."
              : subscriptionPlan.name === PlanTypeV4.CREATOR.name
              ? "Cheaper cost per credit, send images, paid memberships, and import csv."
              : "Lowest cost per credit, unlimited audience lists and dedicated support."}
          </span>
        </div>
        {CardHorizontalDivider}
        {previousSubscriptionOption && subscriptionPlan.price > 0 ? (
          <>
            <div style={{ paddingInline: 14 }}>
              <span style={styles.bodyMediumBold}>
                All features in {previousSubscriptionOption.name} plus:
              </span>
            </div>
            {CardHorizontalDivider}
          </>
        ) : null}
        <div className="ColumnNormal">
          {subscriptionPlan.name === PlanTypeV4.FREE.name
            ? freeSubscriptionCard
            : subscriptionPlan.name === PlanTypeV4.BASIC.name
            ? basicSubscriptionCard
            : subscriptionPlan.name === PlanTypeV4.CREATOR.name
            ? creatorSubscriptionCard
            : businessSubscriptionCard}
        </div>
      </div>
      <div style={{ paddingInline: 14 }}>{cardButton}</div>
    </StandardBorderedContainer>
  );
};

export default SubscriptionPlanCard;
