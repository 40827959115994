import { isDesktop } from "react-device-detect";
import "../../css/GlobalStyles.css";
import { Colors } from "../../utils/colors";
import {
  NotificationType,
  PlanTypeV4,
  subscriptionPricesV4,
  testSubscriptionPricesV4,
} from "@markit/common.types";
import {
  hasSubscription,
  MARKIT_HOTLINE_NUMBER,
  mostRecentSubscription,
} from "@markit/common.utils";
import { useSelector, useDispatch } from "react-redux";
import { getAccountState, LoginState } from "../../redux/slices/accountSlice";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import SubscriptionPlans from "./SubscriptionPlans";
import { FlatMobileTabs } from "../Tabs/FlatMobileTabs";
import SubscriptionReceipt from "./SubscriptionReceipt";
import SubscriptionCheckout from "./SubscriptionCheckout";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MixpanelContext } from "../../context/AnalyticsService";
import { testStripe } from "../FullEvent/FullEventInputForm";
import MarkitLogoBlue from "../../assets/MarkitLogoBlue.png";
import ConfirmActionModal from "../Containers/ConfirmPopups/ConfirmActionModal";
import { NavigationId } from "../../navigation/AppParamList";
import { API } from "../../API";
import { Icon } from "@iconify/react";
import { scrollToTop } from "../../utils/navigationUtils";
import { showNotificationBanner } from "../../utils/notificationUtils";

export enum SubscriptionStage {
  OVERVIEW = "Overview",
  CHECKOUT = "Checkout",
  RECEIPT = "Receipt",
}

type SubscriptionPlansFlowProps = {
  onboardingFreeOnPress?: () => void;
};

const SubscriptionPlansFlow = (props: SubscriptionPlansFlowProps) => {
  const { onboardingFreeOnPress } = props;
  const { state, pathname } = useLocation() || {};
  const { changePlan } = state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appInitialized, loggedIn } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);
  const accountData = useSelector(getAccountState).account.accountData;
  const [subscriptionStage, setSubscriptionStage] = useState<SubscriptionStage>(
    SubscriptionStage.OVERVIEW
  );
  const [email, setEmail] = useState<string>(accountData.email);
  const [selectedCardOption, setSelectedCardOption] = useState<number>(2);
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const isOnboarding = useMemo(
    () => pathname === NavigationId.ONBOARDING,
    [pathname]
  );

  useEffect(() => {
    scrollToTop();
  }, [subscriptionStage]);

  useEffect(() => {
    if (!appInitialized) {
      return;
    }
    if (loggedIn !== LoginState.LOGGED_IN) {
      navigate(NavigationId.WELCOME);
    }
    if (loading) {
      const subscription = mostRecentSubscription(accountData);
      if (hasSubscription(accountData) && subscription) {
        const mostRecentPrice = subscription.price[0];
        if (
          mostRecentPrice.id === PlanTypeV4.BASIC.priceId ||
          mostRecentPrice.id === PlanTypeV4.BASIC.testPriceId
        ) {
          setSelectedCardOption(1);
        } else if (
          mostRecentPrice.id === PlanTypeV4.CREATOR.priceId ||
          mostRecentPrice.id === PlanTypeV4.CREATOR.testPriceId
        ) {
          setSelectedCardOption(2);
        } else {
          setSelectedCardOption(3);
        }
      }
    }
    setLoading(false);
  }, [accountData, appInitialized, loading, loggedIn, navigate]);

  const upgradeSubscription = useCallback(async () => {
    try {
      setLoading(true);
      if (testStripe) {
        const { id, overflow } =
          testSubscriptionPricesV4[selectedCardOption - 1];
        await API.user.updateTestSubscription(accountData.uid, {
          action: "upgrade",
          baseline: id,
          overflow: overflow,
        });
      } else {
        const { id, overflow } = subscriptionPricesV4[selectedCardOption - 1];
        await API.user.updateSubscription(accountData.uid, {
          action: "upgrade",
          baseline: id,
          overflow: overflow,
        });
      }
    } catch (err: any) {
      alert(
        "There was an error changing your subscription. Please try again later"
      );
      console.log(err.message);
    } finally {
      setLoading(false);
      showNotificationBanner(
        dispatch,
        "Upgraded",
        NotificationType.AFFIRMATIVE
      );
      navigate(NavigationId.HOME_BILLING);
    }
  }, [accountData.uid, dispatch, navigate, selectedCardOption]);

  const subscriptionCard = useMemo(
    () =>
      selectedCardOption === 1
        ? PlanTypeV4.BASIC
        : selectedCardOption === 2
        ? PlanTypeV4.CREATOR
        : selectedCardOption === 3
        ? PlanTypeV4.BUSINESS
        : PlanTypeV4.FREE,
    [selectedCardOption]
  );

  const mostRecentPriceAmount = useMemo(() => {
    const subscription = mostRecentSubscription(accountData);
    if (subscription) {
      return subscription.price[0].amount;
    }
    return 0;
  }, [accountData]);

  /**
   * @TODO - Subscripton updating probably should not be here.
   */
  const subscribe = useCallback(
    async (cardOption: number) => {
      if (loading) {
        return;
      }
      if (hasSubscription(accountData) && !changePlan) {
        alert("You already have a subscription!");
        return;
      }
      const action = changePlan
        ? mostRecentPriceAmount > subscriptionCard.price
          ? "downgrade"
          : "upgrade"
        : "";
      // TODO (Peter): Do not allow manual downgrades, until behavior is verified
      if (action === "downgrade") {
        alert(
          `To downgrade your subscription plan, please contact us at ${formatPhoneNumber(
            MARKIT_HOTLINE_NUMBER
          )}. Thank you.`
        );
        return;
      }
      // if upgrading/downgrading plan
      if (action !== "") {
        setSelectedCardOption(cardOption);
        setShowConfirmModal(true);
      } else {
        if (cardOption === 0) {
          mixpanel.track("Webapp: Pressed Subscription Plan Option Free", {
            distinct_id: accountData.uid,
            is_onboarding: isOnboarding,
          });
          if (isOnboarding && onboardingFreeOnPress) {
            onboardingFreeOnPress();
          } else {
            navigate("/home");
          }
        } else {
          // normal subscription checkout
          mixpanel.track("Webapp: Pressed Subscription Plan Option", {
            distinct_id: accountData.uid,
            subscriptionPlan: testStripe
              ? subscriptionCard.testPriceId
              : subscriptionCard.priceId,
            subscriptionPrice: subscriptionCard.price,
            is_onboarding: isOnboarding,
          });
          setSelectedCardOption(cardOption);
          setSubscriptionStage(SubscriptionStage.CHECKOUT);
        }
      }
    },
    [
      loading,
      accountData,
      changePlan,
      mostRecentPriceAmount,
      subscriptionCard.price,
      subscriptionCard.testPriceId,
      subscriptionCard.priceId,
      mixpanel,
      isOnboarding,
      onboardingFreeOnPress,
      navigate,
    ]
  );

  return (
    <div className="ColumnNormal" style={{ paddingBlock: 24, height: "100%" }}>
      {subscriptionStage === SubscriptionStage.RECEIPT ? (
        <SubscriptionReceipt
          subscriptionPlan={subscriptionCard}
          email={email}
        />
      ) : subscriptionStage === SubscriptionStage.CHECKOUT ? (
        <SubscriptionCheckout
          subscriptionPlan={subscriptionCard}
          setSubscriptionStage={setSubscriptionStage}
          email={email}
          setEmail={setEmail}
        />
      ) : (
        <div className="ColumnNormal">
          <div
            className={isDesktop ? "ColumnCentering" : "ColumnNormal"}
            style={{
              gap: 30,
              paddingInline: 14,
              paddingTop: 40,
              paddingBottom: isDesktop ? 40 : 0,
            }}
          >
            <div
              className={isDesktop ? "ColumnCentering" : "ColumnNormal"}
              style={{ gap: 20 }}
            >
              <div>
                <img
                  style={{ height: 20, width: "auto", objectFit: "cover" }}
                  src={MarkitLogoBlue}
                  alt={"markitLogoBlue"}
                />
              </div>
              <div
                className={isDesktop ? "ColumnCentering" : "ColumnNormal"}
                style={{ gap: 12 }}
              >
                <span style={{ fontSize: 20, fontWeight: 500 }}>
                  Select a plan to continue
                </span>
                <span
                  style={{
                    color: Colors.GRAY2,
                    fontSize: 12,
                    textAlign: isDesktop ? "center" : "left",
                  }}
                >
                  Markit offers additional features, texting, and 24/7
                  assistance.
                  <br />
                  For enterprise solutions or questions{" "}
                  <a
                    href={`sms:${MARKIT_HOTLINE_NUMBER}`}
                    style={{ color: Colors.BLACK }}
                  >
                    Get in touch
                  </a>
                  <Icon
                    icon="ion:chevron-right"
                    height={10}
                    color={Colors.BLACK}
                    style={{ marginBottom: -1, marginLeft: 1 }}
                  />
                </span>
              </div>
            </div>
            {!isDesktop ? (
              <FlatMobileTabs
                tabValue={selectedCardOption}
                setTabValue={setSelectedCardOption}
                tabNames={[
                  PlanTypeV4.FREE.name,
                  PlanTypeV4.BASIC.name,
                  PlanTypeV4.CREATOR.name,
                  PlanTypeV4.BUSINESS.name,
                ]}
                spacing={24}
                containerStyles={{ paddingLeft: 10 }}
              />
            ) : null}
          </div>
          <div
            className="ColumnNormal"
            style={{
              backgroundColor: Colors.WHITE1,
              padding: isDesktop
                ? "30px 80px 80px 80px"
                : "24px 14px 40px 14px",
            }}
          >
            <SubscriptionPlans
              selectedCardOption={selectedCardOption}
              setSelectedCardOption={setSelectedCardOption}
              itemOnPress={subscribe}
            />
          </div>
        </div>
      )}
      <ConfirmActionModal
        heading={"Upgrade Subscription?"}
        subtext={
          "Are you sure you want to upgrade your subscription? This cannot be undone."
        }
        confirmButtonText="Yes"
        hideModal={!showConfirmModal}
        setIsVisible={setShowConfirmModal}
        confirmOnPress={upgradeSubscription}
      />
    </div>
  );
};

export default SubscriptionPlansFlow;
