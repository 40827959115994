import "../../css/Subscription.css";
import { memo, useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useOnMount } from "../../hooks/useOnMount";
import { useNavigate } from "../../hooks/useNavigate";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import RectangleButton from "../Buttons/RectangleButton";
import { SubscriptionPlanType } from "@markit/common.types";
import { FormatCurrency } from "../../utils/FormatCurrency";
import { hasSubscription } from "@markit/common.utils";
import { isDesktop } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { NavigationId } from "../../navigation/AppParamList";

type SubscriptionReceiptProps = {
  subscriptionPlan: SubscriptionPlanType;
  email: string;
};

const SubscriptionReceipt = memo(function SubscriptionReceiptFn(
  props: SubscriptionReceiptProps
) {
  const { subscriptionPlan, email } = props;
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const mixpanel = useContext(MixpanelContext);
  const { accountData } = useSelector(getAccountState).account;

  const styles = {
    body: { fontSize: 14, color: Colors.GRAY1 },
  };

  const isOnboarding = useMemo(
    () => pathname === NavigationId.ONBOARDING,
    [pathname]
  );

  const isTrialSubscription = useMemo(
    () => accountData.customer.state === "TRIAL",
    [accountData.customer.state]
  );

  useOnMount(() => {
    mixpanel.track("Webapp: View Subscription Receipt Page", {
      distinct_id: accountData.uid,
      is_onboarding: isOnboarding,
    });
  });

  // We want to navigate the user to the verification account info flow
  const continueOnPress = useCallback(() => {
    navigate(NavigationId.SECONDARY_ONBOARDING);
  }, [navigate]);

  return (
    <>
      <div className="ColumnNormal">
        <div className="ColumnCentering" style={{ gap: 28, paddingBlock: 28 }}>
          <StandardBorderedContainer containerStyles={{ padding: 14 }}>
            <span style={{ fontSize: 14, fontWeight: 600 }}>
              Welcome to Markit<span style={{ color: Colors.BLUE5 }}>+</span>
            </span>
          </StandardBorderedContainer>
          <span style={{ fontSize: 32, fontWeight: 500 }}>Success!</span>
        </div>
        <div
          className="ColumnNormal HideScrollbar"
          style={{
            alignItems: "center",
            backgroundColor: Colors.WHITE1,
            padding: "30px 80px 24px 80px",
            minHeight: isDesktop ? "70vh" : undefined,
          }}
        >
          <StandardBorderedContainer
            containerStyles={{
              backgroundColor: Colors.WHITE,
              padding: 14,
              borderRadius: 20,
              width: 362,
              display: "flex",
              flexDirection: "column",
              gap: 24,
            }}
          >
            <StandardBorderedContainer
              containerStyles={{
                padding: 14,
                display: "flex",
                flexDirection: "column",
                gap: 7,
              }}
            >
              <span style={styles.body}>
                {subscriptionPlan.name} Plan
                {isTrialSubscription ? ": Trial" : ""}
              </span>
              <span style={styles.body}>
                Total:{" "}
                {FormatCurrency(
                  isTrialSubscription ? 0 : subscriptionPlan.price / 100
                )}
              </span>
              <span style={styles.body}>(Receipt sent to {email})</span>
            </StandardBorderedContainer>
            <RectangleButton
              buttonLabel="Continue"
              onPress={continueOnPress}
              altPaddingVert={14}
              altPaddingHorz={14}
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
              loading={!hasSubscription(accountData)}
            />
          </StandardBorderedContainer>
        </div>
      </div>
    </>
  );
});

export default SubscriptionReceipt;
